import React, { useState, useEffect, useRef } from 'react';

// Bootstrap - CSS 樣式文件
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

// Material-UI components
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

//Air Datepicker
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

//line-liff : https://developers.line.biz/en/docs/liff/overview/
import liff from '@line/liff'; 

// KAH components
import KahHeaderLogo from './components/KahHeaderLogo'

const LIFFID = '2005573301-yJdo0VW8';

function RepaymentForm() {
    const [loading, setLoading] = useState(false); // loading state
    const [success, setSuccess] = useState(false); // success state
    const timerRef = React.useRef();
    const datepickerRef = useRef(null);
    const datepickerInstance = useRef(null); // 用於儲存日期選擇器實例
    const [caseNames, setCaseNames] = useState([]);

    //repayment form data
    const [formData, setFormData] = useState({
        requester: '', // 請款人
        department: '', // 部門
        buyDate: '', // 購買日期
        category: '', // 分類
        caseNumber: '', // 案號
        description: '', // 內容
        remarks: '', // 備註
        totalAmount: '', // 合計
        invoiceNeeded: '' // 開立發票
    });

    const zh_TW = {
        days: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        daysShort: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
        daysMin: ['日', '一', '二', '三', '四', '五', '六'],
        months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        monthsShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        today: '今天',
        clear: '清除',
        dateFormat: 'yyyy/MM/dd',
        timeFormat: 'hh:mm aa',
        firstDay: 0
    };
    // 初始化日期選擇器
    useEffect(() => {
        if (datepickerRef.current && !datepickerInstance.current) {
            datepickerInstance.current = new AirDatepicker(datepickerRef.current, {
                locale: zh_TW, // 設定語言
                dateFormat: 'yyyy/MM/dd', // 設定日期格式
                autoClose: true, // 選擇日期後自動關閉日曆
                onSelect({ formattedDate }) {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        buyDate: formattedDate
                    }));
                }
            });
        }
    }, []);

    // 初始化 LIFF 並獲取使用者資料
    useEffect(() => {
        liff.init({ liffId: LIFFID }) // 替換為你的 LIFF ID
            .then(() => {
                if (!liff.isLoggedIn()) {
                    liff.login(); // 如果未登入則要求登入
                } else {
                    liff.getProfile()
                        .then(profile => {
                            const displayName = profile.displayName; // 取得 LINE 名稱
                            console.log('使用者名稱：', displayName);
                            // 將名稱填入表單
                            setFormData(prevData => ({
                                ...prevData,
                                requester: displayName
                            }));
                        })
                        .catch(err => {
                            console.error('抓取使用者資料失敗', err);
                        });
                }
            })
            .catch(err => {
                console.error('LIFF 初始化失敗', err);
            });
    }, []);

    useEffect(() => {
        // 向後端請求專案名稱（作為案號）
        axios.get('https://kah.dianalab.net/api/get-project-cases')
            .then(response => {
                setCaseNames(response.data);
            })
            .catch(error => {
                console.error('取得案號時發生錯誤:', error);
            });
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    

    const handleInvoiceChange = (event) => {
        const { name, value } = event.target;
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    // 載入狀態
    const handleClose = () => {
        setLoading(false);
        setSuccess(false);
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (Object.values(formData).some(x => x === '')) {
            alert('所有欄位都必須填寫！');
            return;
        }
        console.log('repayment_form data:', formData);
        setLoading(true);
        fetch('https://kah.dianalab.net/api/repayment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
                setSuccess(false);
            }, 1500);  // Hide success message after 3 seconds
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);
            alert('提交失敗，請稍後再試！');
        });
    };
    
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Form className="mb-3" onSubmit={handleSubmit}>
                <KahHeaderLogo />

                <Form.Group className="mb-2" controlId="requester">
                    <Form.Label>請款人</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="請輸入您的名字" 
                        value={formData.requester} 
                        onChange={handleInputChange} 
                        name="requester"
                        readOnly 
                   />
                </Form.Group>

                <Form.Group className="mb-2" controlId="department">
                    <Form.Label>部門</Form.Label>
                    <Form.Select 
                        value={formData.department} 
                        onChange={handleInputChange} 
                        name="department"
                    >
                        <option value="">選擇部門</option>
                        <option value="01">設計</option>
                        <option value="02">管理</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-2" controlId="buyDate">
                    <Form.Label>購買日期</Form.Label>
                    <input
                        type="text"
                        ref={datepickerRef}
                        className="form-control"
                        placeholder="請選擇時間"
                    />
                    {/* <Form.Control type="date" value={formData.buyDate} onChange={handleInputChange}/> */}
                </Form.Group>

                <Form.Group className="mb-2" controlId="category">
                    <Form.Label>分類</Form.Label>
                    <Form.Select 
                        value={formData.category} 
                        onChange={handleInputChange}
                        name="category"
                    >
                        <option value="">選擇分類</option>
                        <option value="01">工程</option>
                        <option value="02">管銷</option>
                        <option value="03">行銷</option>
                        <option value="04">知光合禾</option>
                        <option value="05">上誼</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-2" controlId="caseNumber">
                    <Form.Label>案號</Form.Label>
                        <Form.Select 
                            value={formData.caseNumber} 
                            onChange={handleInputChange}
                            name="caseNumber"
                        >
                            <option value="" disabled>選擇案號</option>
                            {caseNames.map((name, index) => (
                                <option key={index} value={name}>{name}</option>
                            ))}
                        </Form.Select>
                </Form.Group>

                <Form.Group className="mb-2" controlId="description">
                    <Form.Label>內容(例如: 拉力頭, HDMI線*2, 門把)</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="請輸入內容" 
                        value={formData.description} 
                        onChange={handleInputChange}
                        name="description"
                    />
                </Form.Group>

                <Form.Group className="mb-2" controlId="remarks">
                    <Form.Label>備註(例如: YAHOO拍賣, PChome, 蝦皮)</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="請輸入備註" 
                        value={formData.remarks} 
                        onChange={handleInputChange}
                        name='remarks'
                    />
                </Form.Group>

                <Form.Group className="mb-2" controlId="totalAmount">
                    <Form.Label>合計</Form.Label>
                    <Form.Control 
                        type="number" 
                        placeholder="請輸入金額" 
                        value={formData.totalAmount} 
                        onChange={handleInputChange}
                        name='totalAmount'
                    />
                </Form.Group>

                <Form.Group className="mb-2" controlId="invoiceOptions">
                    <Form.Label>開立發票</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                            inline
                            label="是"
                            type="radio"
                            id={`inline-radio-1`}
                            value="是"
                            checked={formData.invoiceNeeded === '是'}
                            onChange={handleInvoiceChange}
                            name="invoiceNeeded"
                        />
                        <Form.Check
                            inline
                            label="否"
                            type="radio"
                            id={`inline-radio-2`}
                            value="否"
                            checked={formData.invoiceNeeded === '否'}
                            onChange={handleInvoiceChange}
                            name="invoiceNeeded"
                        />
                    </div>
                </Form.Group>
                <div className='d-flex justify-content-center'><Button variant="success" type="submit">
                    提交
                </Button></div>
                
            </Form>

            {/* Material-UI Dialog with Progress Indicator */}
            <Dialog open={loading || success} onClose={handleClose}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        {loading && <CircularProgress size={68} sx={{ color: green[500] }} />}
                        {success && (
                            <>
                                <CheckIcon sx={{ color: green[500], fontSize: 60 }} />
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    成功提交
                                </Typography>
                            </>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
  )
}

export default RepaymentForm
