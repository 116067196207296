//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom';
//MaterialUI : https://mui.com/material-ui/getting-started/
//https://mui.com/material-ui/getting-started/templates/
//https://mui.com/x/react-data-grid/editing/#full-featured-crud

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";
import useAirDatepicker from '../hooks/useAirDatepicker';

function Clock_back_add() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
    const userData = useUserData(); // Cookies 取得userId、userName資料
    const datepickerRef = useAirDatepicker({
        onSelect({ date, formattedDate }) {
            handleInputChange({ target: { id: 'work_date', value: formattedDate } });
        },
    });
    const [formData, setFormData] = useState({
        name: '',
        work_date: '',
        on_time: '08:30',
        off_time: '17:30',
        overtime_note: '',
        location: '',
        fill_form_member: '',
    });
    const [employees, setEmployees] = useState([]); // 員工清單狀態
    const [locations, setLocations] = useState([]); // 用於儲存打卡地點列表
    const location = useLocation();
    const allowedRoles = {
        features: ['clock'],
        line_permission:["SYSAdmin", "Boss", "HR"]
    };
    useCheckPermission(userData , allowedRoles, "/manager/clock_back");

    // 從 URL 參數中提取員工姓名
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const employeeParam = params.get('employee');
        if (employeeParam) {
            setFormData((prevData) => ({
                ...prevData,
                name: decodeURIComponent(employeeParam)
            }));
        }
    }, [location]);


    // 填表人的資料
    useEffect(() => {
        if (userData) {
            setFormData((prevData) => ({
                ...prevData,
                fill_form_member: userData.userName,
            }));
        }
    }, [userData]);
    

    // 抓取打卡地點的資料
    useEffect(() => {
        axios.get('https://kah.dianalab.net/api/manager/clock_location')
            .then(response => setLocations(response.data))
            .catch(error => console.error('Error fetching locations:', error));
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        const formattedData = {
            ...formData,
            work_date: formData.work_date.replace(/\//g, '-'), // 替換 '/' 為 '-'
            overtime: parseFloat(formData.overtime) || 0, // 支援小數點
            latetime: parseFloat(formData.latetime) || 0, // 支援小數點
            overtime_note: parseFloat(formData.note) || 0, // 支援小數點
            fill_form_member: userData.userId,
        };
    
        console.log("clock_back_add data:", formattedData);
        axios.post('https://kah.dianalab.net/api/create-clock-record', formattedData)  // 使用 formattedData
            .then(response => {
                console.log('Response:', response.data);
                window.location.href = '/manager/clock_back'; // 直接導向到指定URL
            })
            .catch(error => {
                console.error('There was an error creating the clock record!', error);
            });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;

        setFormData({
            ...formData,
            [id]: value
        });
    };
          
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* SIDE BAR */}
            <KahHeaderManager />

            {/* MAIN CONTENT */}
            <div style={{ flex: 1, paddingBottom: '100px' }}> {/* flex: 1 保證內容撐滿剩餘空間 */}
                {/* Logo */}
                <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
                {/* Content */}
                <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                    <h2>打卡後台</h2>
                    <p>共生製作 - 打卡後台</p>
                    <Form onSubmit={handleSubmit}>
                        {/* 員工姓名 */}
                        <Form.Group controlId="name" className='mt-2'>
                            <Form.Label>員工姓名</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="請輸入員工姓名"
                                value={formData.name}
                                onChange={handleInputChange}
                                readOnly
                            />
                        </Form.Group> 

                        {/* 上班日期 */}
                        <Form.Group controlId="work_date" className="mt-2">
                            <Form.Label>工作日期</Form.Label>
                            <input
                                type="text"
                                ref={datepickerRef}
                                className="form-control"
                                placeholder="請選擇工作日期"
                                defaultValue={formData.project_start}
                            />
                        </Form.Group>

                        {/* 上班時間 */}
                        <Form.Group controlId="on_time" className='mt-2'>
                            <Form.Label>上班時間</Form.Label>
                            <Form.Control
                                type="time"
                                value={formData.on_time}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        {/* 下班時間 */}
                        <Form.Group controlId="off_time" className='mt-2'>
                            <Form.Label>下班時間</Form.Label>
                            <Form.Control
                                type="time"
                                value={formData.off_time}
                                placeholder="17:30"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
        
                        {/* 遲到時數 
                        <Form.Group controlId="latetime" className='mt-2'>
                            <Form.Label>遲到時數</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.latetime}
                                onChange={handleInputChange}
                                readOnly
                            />
                        </Form.Group>

                        加班時間
                        <Form.Group controlId="overtime" className='mt-2'>
                            <Form.Label>加班時間</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.overtime}
                                onChange={handleInputChange}
                                readOnly
                            />
                        </Form.Group> */}
                        

                        {/* 加班時間備註 */}
                        <Form.Group controlId="overtime_note" className='mt-2'>
                            <Form.Label>加班時間(人工)</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.overtime_note}
                                onChange={handleInputChange}
                                placeholder="選填"
                            />
                        </Form.Group>

                        {/* 打卡地點 */}
                        <Form.Group controlId="location" className='mt-2'>
                            <Form.Label>打卡地點</Form.Label>
                            <Form.Select
                                value={formData.location}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>選擇地點</option>
                                {locations.map((loc) => (
                                    <option key={loc.id} value={loc.location}>
                                        {loc.location}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        
                        {/* 填表人姓名 */}
                        <Form.Group controlId="fill_form_member" className='mt-2'>
                            <Form.Label>填表人</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="請輸入填表人姓名"
                                value={formData.fill_form_member}
                                onChange={handleInputChange}
                                readOnly
                            />
                        </Form.Group> 

                        {/* 提交按鈕 */}
                        <div className="d-flex justify-content-center">
                            <Button variant="success" className="mt-3" type="submit">提交</Button>
                        </div>
                    </Form>
                </div>
            </div>
            {/* Footer */}
            <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        </div>
    );
}

export default Clock_back_add;
