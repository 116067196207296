import React from 'react'
import Button from 'react-bootstrap/Button';
function clock_delete() {
  const delData = async () => {
    try {
      const response = await fetch('https://kah.dianalab.net/api/clock_del', { 
        method: 'DELETE', // Using DELETE method
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      alert('打卡記錄已成功刪除');
    } catch (error) {
      console.error('Error:', error);
      alert('刪除打卡記錄失敗');
    }
  };
  return (
    <div className="d-flex justify-content-center mt-5" > 
      <Button onClick={delData}>刪除一筆打卡紀錄</Button>
    </div>
  )
}

export default clock_delete
