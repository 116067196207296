import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// 組件現在接受 data 作為屬性
const MUITable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>序號</StyledTableCell>
            <StyledTableCell align="left">日期</StyledTableCell>
            <StyledTableCell align="left">分類</StyledTableCell>
            <StyledTableCell align="left">案號</StyledTableCell>
            <StyledTableCell align="left">內容</StyledTableCell>
            <StyledTableCell align="left">備註</StyledTableCell>
            <StyledTableCell align="left">合計</StyledTableCell>
            <StyledTableCell align="right">開立發票</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row" align="center">
                {index + 1} {/* 序號是基於索引生成的 */}
              </StyledTableCell>
              <StyledTableCell align="center">{row.購買日期}</StyledTableCell>
              <StyledTableCell align="center">{row.分類}</StyledTableCell>
              <StyledTableCell align="left">{row.案號}</StyledTableCell>
              <StyledTableCell align="left">{row.內容}</StyledTableCell>
              <StyledTableCell align="left">{row.備註}</StyledTableCell>
              <StyledTableCell align="center">{row.合計}</StyledTableCell>
              <StyledTableCell align="center">{row.開立發票}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MUITable;