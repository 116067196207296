//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import axios from "axios";
import Cookies from "js-cookie"; // 用於操作 Cookies

//KAH config
import LinebotType from "../config/line_bot_type"; // LINE Bot 類型設定

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeaderLogo from '../components/KahHeaderLogo'

//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useLineAuth from '../hooks/useLineAuth';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";

function Home_back() {
  const { user, loading} = useLineAuth(LinebotType, "manager"); //botType = "test" or "kah", site = "manager"
  const userData = useUserData("manager"); // 使用者資料
  const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
  const navigate = useNavigate(); // 頁面導覽

  // 檢查使用者權限(SYSAdmin、Boss、HR、Staff、Customer、Other)
  // useEffect(() => {
  //   console.log("loading:", loading)
  //   if (loading && user?.permission) {
  //     const allowedRoles = ["SYSAdmin", "Boss", "HR", "Staff"]; // 允許進入的權限
  //     if (!allowedRoles.includes(user.permission.line_permission)) {
  //       console.error("Insufficient permission. Redirecting to homepage...");
  //       navigate("/"); // 非允許權限者，重新導向首頁
  //     }
  //     if (user.permission.line_permission === "Other") {
  //       navigate("/"); // SYSAdmin 導向管理頁面
  //     }
  //   }
  // }, [user, loading, navigate]);
  
  useEffect(() => {
    // console.log("loading:", loading)
    // console.log("permission:", user?.permission)
    if (!loading) {
      // console.log("User:", user);
      const allowedRoles_login = ["SYSAdmin", "Boss", "HR", "Staff"]; // 允許進入的權限
      const line_permission = user.permission.user_permission.line_permission
      
      if (!allowedRoles_login.includes(line_permission)) {
        console.error("Insufficient permission. Redirecting to homepage...");
        navigate("/"); // 非允許權限者，重新導向首頁
      }
      else{
        console.log("permission correct");
        // console.log("permission:",line_permission);
      }
    }
  }, [loading, navigate]);
  
  const allowedRoles_cookie = {
    features: [],
    line_permission:["SYSAdmin", "Boss", "HR", "Staff"]
  };
  useCheckPermission(userData , allowedRoles_cookie, "/");

  return (
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'row' }}>
      
      {/* SIDE BAR */}
      <KahHeaderManager />
        
      {/* MAIN CONTENT */}
      <div
        className="main-content"
        style={{
          marginLeft: isSidebarOpen && !isMobile ? '250px' : '0px' , // 確保主內容部分移動250px以避開側邊欄
          marginTop: isSidebarOpen && !isMobile ? '-50px' : '-25px' ,
          width: isSidebarOpen && !isMobile ? 'calc(100% - 250px)' : '100%', // 減去側邊欄的寬度
          padding: isMobile ? '0 50px' : '0',
          boxSizing: 'border-box',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // 垂直居中
          alignItems: 'center', // 水平居中
          textAlign: 'center',
        }}
      >
        {/* Logo */}
        <KahHeaderLogo style={{width: '400px'}}/>

        {/* Content */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isMobile ? (
            // 手機版顯示帶換行的內容
            <h4>
              共生製作<br />+<br />知光合禾建築師事務所
            </h4>
          ) : (
            // 桌面版顯示原始內容
            <h4>共生製作+知光合禾建築師事務所</h4>
          )}
        </div>

      </div>

      {/* Footer */}
      {/* <KahFooter /> */}

    </div>
  );
}

export default Home_back;

