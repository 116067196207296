import React from 'react'
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Nav from 'react-bootstrap/Nav';

import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'

function Wish(){
    return(
    <div>
        <KahHeader/>
        <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>留言時間</th>
                <th>留言內容</th>
                {/* <th>記錄</th> */}
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>2024/1/23 上午 1:18:17</td>
                <td>零食：蝦味先<br></br>飲料：可樂<br></br>其他許願建議：無</td>
                {/* <td>
                    <Form>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check // prettier-ignore
                                inline
                                label={`保存`}
                                name="save"
                                type={type}
                                id={`inline-${type}-save`}
                            />
                            <Form.Check // prettier-ignore
                                inline
                                label={`刪除`}
                                name="save"
                                type={type}
                                id={`inline-${type}-save`}
                            />
                            </div>
                        ))}
                    </Form>
                </td> */}
                </tr>
                <tr>
                <td>2</td>
                <td>2024/1/23 上午 1:21:30</td>
                <td>零食：可樂果<br></br>飲料：雪碧<br></br>其他許願建議：無</td>
                {/* <td>
                    <Form>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check // prettier-ignore
                                inline
                                label={`保存`}
                                name="save"
                                type={type}
                                id={`inline-${type}-save`}
                            />
                            <Form.Check // prettier-ignore
                                inline
                                label={`刪除`}
                                name="save"
                                type={type}
                                id={`inline-${type}-save`}
                            />
                            </div>
                        ))}
                    </Form>
                </td> */}
                </tr>
            </tbody>
        </Table>
        {/* <div className="d-flex justify-content-center">
            <Button variant="success" type="submit" value="Submit">存檔</Button>{' '}
        </div> */}
        <Nav className="d-flex justify-content-end">
            <Nav.Link href="https://docs.google.com/spreadsheets/d/1nJB5TwrhRhI8zv16OKFzx6e10v2r7IIcwQ31yk6Onzk/edit#gid=1216954832">前往表單></Nav.Link>
        </Nav>
        <KahFooter/>
    </div>
    )
}

export default Wish