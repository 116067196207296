//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import axios from 'axios';
//MaterialUI : https://mui.com/material-ui/getting-started/
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; //MaterialUI icon : https://mui.com/material-ui/material-icons/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeader from '../components/KahHeaderText'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'

function Repair_back() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
    const [isMobile, setIsMobile] = useState(false);  // 手機與電腦
    const [repairs, setRepairs] = useState([]);  // 請款紀錄
    // 根據視窗大小監控是否為手機視圖
    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
            setIsMobile(true);
            setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
        } else {
            setIsMobile(false);
            setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // 抓取"後端"資料
    useEffect(() => {
      
        axios.post('https://kah.dianalab.net/api/get-repair')
        .then(response => {
            setRepairs(response.data);
            console.log('Repament records:', response.data);
        })
        .catch(error => {
            console.error('Error fetching clock records:', error);
        });
        
    }, []);

    function handleEditRepayment(){
        window.location.href = '/manager/repayment_back/search'
    }

    function handleToRepairForm(){
        window.location.href = '/repair_form'
    }
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        {<KahHeaderManager/>}
  
        {/* MAIN CONTENT */}
        <div style={{ flex: 1 }}>
        {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

        {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                    <h2>報修後台</h2>
                    <p>共生製作-報修後台</p>
                    
                    <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">報修人</th>
                        <th scope="col">手機</th>
                        <th scope="col">報修項目</th>
                        <th scope="col">其他說明</th>
                        <th scope="col">維修進度</th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        {repairs.map((repair, index) => (
                            <tr key={index}>
                                <th scope="row">{repair.reporter}</th>
                                <td>{repair.phone}</td>
                                <td>{repair.repair_item}</td>
                                <td>{repair.additional_info || '無'}</td>
                                <td>
                                    <Button variant="outline-dark" size="sm" onClick={handleEditRepayment}>
                                        查詢
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                    <div className="text-center d-flex flex-column ms-1 mt-3">
                        <Button className="mt-1" style={{ width: '200px' }} variant="outline-primary" size="sm" onClick={handleToRepairForm}>
                            報修表單
                        </Button>
                        <Button className="mt-3" style={{ width: '200px' }} variant="outline-success" size="sm" >
                            報修紀錄
                        </Button>
                    </div>
                </div>  
                
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Repair_back
