import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function MUISelection() {
  const [Year, setYear] = React.useState('');

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const [Month, setMonth] = React.useState('');

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const [Name, setName] = React.useState('');

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  return (
    <div className="d-flex justify-content-center align-items-center">
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Year</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={Year}
          onChange={handleChangeYear}
          label="Year"
        >
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Month</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={Month}
          onChange={handleChangeMonth}
          label="Month"
        >
          <MenuItem value={1}>January</MenuItem>
          <MenuItem value={2}>February</MenuItem>
          <MenuItem value={3}>March</MenuItem>
          <MenuItem value={4}>April</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>June</MenuItem>
          <MenuItem value={7}>July</MenuItem>
          <MenuItem value={8}>August</MenuItem>
          <MenuItem value={9}>September</MenuItem>
          <MenuItem value={10}>October</MenuItem>
          <MenuItem value={11}>November</MenuItem>
          <MenuItem value={12}>December</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Name</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={Name}
          onChange={handleChangeName}
          label="Name"
        >
          <MenuItem value={1}>李仁傑</MenuItem>
          <MenuItem value={2}>ooo</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
