import React from 'react'

function KahHeaderLogo() {
  return (
    <div className="text-center ms-3 mt-4 mb-3">
        <img src="../../KAH_Design_logo.png" alt="KAH Design Logo" style={{ height: 'auto', width: '300px', maxWidth: '100%', marginLeft: '-30px'}} />
        {/* <img src="../KAH_Design_logo.png" alt="KAH Design Logo" style={{ height: 'auto', width: '300px', maxWidth: '100%' , marginLeft: '-100px'}} /> */}
    </div>
  )
  
}

export default KahHeaderLogo
