import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

import KahHeaderLogo from './components/KahHeaderLogo';

function Work_overtime_form() {
  const [loading, setLoading] = useState(false); // 載入狀態
  const [success, setSuccess] = useState(false); // 載入成功
  const timerRef = React.useRef();

  // 使用 useState 來初始化表單數據
  const [formData, setFormData] = useState({
    name: '',
    caseName: '',  // 案件名稱
    description: ''  // 說明內容
  });

  // 處理資料輸入
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // 處理提交
  const handleSubmit = (event) => {
    event.preventDefault();

    // 檢查所有欄位是否填寫
    if (!formData.name || !formData.caseName || !formData.description) {
      alert('所有欄位都必須填寫！');
      return;
    }

    setLoading(true);
    setSuccess(false);

    // 設定要發送的數據
    const payload = {
      name: formData.name,
      caseName: formData.caseName,
      description: formData.description
    };

    // 使用 fetch API 發送 POST 請求
    fetch('http://127.0.0.1:8000/overtime', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Success:', data);
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false); // Optionally hide success message after a delay
        }, 3000); // Hide success message after 3 seconds
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
        alert('提交失敗，請稍後再試！');
      });
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Form onSubmit={handleSubmit}>
        <KahHeaderLogo />

        <Form.Group controlId="formBasicName">
          <Form.Label>姓名</Form.Label>
          <Form.Control
            type="text"
            placeholder="請輸入您的名字"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="formBasicCaseName">
          <Form.Label>選擇案件名稱</Form.Label>
          <Form.Select id="caseName" value={formData.caseName} onChange={handleInputChange}>
            <option>選擇案件</option>
            <option value="Case 1">案件 1</option>
            <option value="Case 2">案件 2</option>
            <option value="Case 3">案件 3</option>
            {/* 根據需要添加更多選項 */}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-2" controlId="formBasicDescription">
          <Form.Label>說明內容</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="請輸入加班說明"
            id="description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </Form.Group>

        <div className="d-flex justify-content-center">
          <Button variant="success" className="mt-3" type="submit">
            提交
          </Button>
        </div>
      </Form>

      {/* Material-UI Dialog with Progress Indicator */}
      <Dialog open={loading || success} onClose={() => setLoading(false)}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
            {loading && <CircularProgress size={68} sx={{ color: green[500] }} />}
            {success && (
              <>
                <CheckIcon sx={{ color: green[500], fontSize: 60 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  成功提交
                </Typography>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Work_overtime_form;
