//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
//MaterialUI : https://mui.com/material-ui/getting-started/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'
import MonthSwitch from '../components/MonthSwitch'

function Absence_card() {
  const [currentMonth, setCurrentMonth] = useState('');  // 狀態保存選擇的月份
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
  const [isMobile, setIsMobile] = useState(false);  // 手機與電腦
  
  // 根據視窗大小監控是否為手機視圖
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
        setIsMobile(true);
        setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
      } else {
        setIsMobile(false);
        setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function Back_to_Absence(){
    window.location.href = '/manager/absence_back'
}
  const handleMonthChange = (month) => {
    // 僅當月份更改時，發送請求
    if (month !== currentMonth) {
      setCurrentMonth(month);
      console.log('Month updated to:', month);
    }
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* SIDE BAR */}
      {<KahHeaderManager/>}

      {/* MAIN CONTENT */}
      <div style={{ flex: 1 }}>
      {/* logo */}
      <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

      {/* content */}
      <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                <h2>請假後台</h2>
                <p>共生製作-員工請假卡後台</p>
                <div className='d-flex align-items-center'>
                  <MonthSwitch onMonthChange={handleMonthChange} />  {/* 傳入 handleMonthChange */}
                  <h5 className='mt-2'>請假紀錄</h5>
                </div>
                <table class="table" >
                  <thead >
                    <tr>
                      <th scope="col">員工姓名</th>
                      <th scope="col">到職日期</th>
                      <th scope="col">特休假</th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    <tr>
                      <th scope="row">員工1</th>
                      <th>2021-08-09</th>
                      <td>113-01-01起特休日數：8日(112年結餘)<br/>113-08-09起特休日數：10日</td>
                    </tr>

                  </tbody>
                </table>
                <table class="table" >
                  <thead>
                    <tr>
                      <th scope="col">假別</th>
                      <th scope="col">請假起訖</th>
                      <th scope="col">請假日數</th>
                      <th scope="col">假由</th>
                      <th scope="col">假別扣除</th>
                      <th scope="col">假別剩餘</th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    <tr>
                      <th scope="row">03-病假</th>
                      <td>2024/3/22 上午 9:00:00 - 2024/3/22 下午 5:00:00</td>
                      <td>1日</td>
                      <td>感冒</td>
                      <td>病假-1日</td>
                      <td>特休-8日</td>
                    </tr>
                  </tbody>
                </table>
                <div className='text-center'>
                  <Button className="me-4" variant="danger" size="sm" onClick={Back_to_Absence}>返回</Button>
              </div>
            </div>
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Absence_card
