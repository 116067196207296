//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import axios from 'axios';
//MaterialUI : https://mui.com/material-ui/getting-started/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo';

function Absence_back_check() {
  const [absence_check, setAbsence_check] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
  const [isMobile, setIsMobile] = useState(false);  // 手機與電腦
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // 根據視窗大小監控是否為手機視圖
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
        setIsMobile(true);
        setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
      } else {
        setIsMobile(false);
        setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 抓取"後端"資料
  useEffect(() => {
    setLoading(true);
    axios.post('https://kah.dianalab.net/api/get-absence-check')
        .then(response => {
          console.log(response.data);  
          setAbsence_check(response.data);
        })
        .catch(error => {
            console.error('Error fetching data: ', error);
            setError(error);
        });
  }, []);

  const handleApproval = (absenceId, isApproved) => {
    // 根據同意/不同意來決定 payload 的內容
    const payload = isApproved
      ? { is_check: 1 } // 同意的情況，更新 is_check 為 1
      : { is_delete: 1 }; // 不同意的情況，更新 is_delete 為 1
  
    // 發送 POST 請求到後端進行更新
    axios.post(`https://kah.dianalab.net/api/update-absence/${absenceId}`, payload)
      .then(response => {
        console.log('Success:', response.data);
        window.location.reload();
        // 你可以在這裡更新本地狀態或顯示提示訊息
      })
      .catch(error => {
        console.error('Error:', error);
        alert('更新失敗，請稍後再試！');
      });
  };
  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  // 計算請假日數
  const calculateLeaveDays = (start_time, end_time) => {
    const start = new Date(start_time);
    const end = new Date(end_time);

    // 如果起訖日期是同一天，直接計算當天的總時數，且最多為 9 小時
    if (start.toDateString() === end.toDateString()) {
        const totalHours = Math.min((end - start) / (1000 * 60 * 60), 9); // 每天最多計算9小時
        return (totalHours / 9).toFixed(2) + '日';
    }

    // 計算第一天的工時，最多 9 小時
    const endOfFirstDay = new Date(start);
    endOfFirstDay.setHours(23, 59, 59, 999); // 設置第一天的結束時間為23:59
    const firstDayHours = Math.min((endOfFirstDay - start) / (1000 * 60 * 60), 9); // 最多9小時

    // 計算最後一天的工時，最多 9 小時
    const startOfLastDay = new Date(end);
    startOfLastDay.setHours(0, 0, 0, 0); // 設置最後一天的開始時間為00:00
    const lastDayHours = Math.min((end - startOfLastDay) / (1000 * 60 * 60), 9); // 最多9小時

    // 計算中間完整的天數，每天按 9 小時計算
    const fullDays = Math.max(0, (startOfLastDay - endOfFirstDay) / (1000 * 60 * 60 * 24) - 1);
    const fullDaysHours = fullDays * 9; // 每天9小時

    // 總共的請假日數
    const totalLeaveDays = (firstDayHours + fullDaysHours + lastDayHours) / 9;

    return totalLeaveDays.toFixed(2) + '日'; // 保留兩位小數
};



  function Back_to_Absence(){
    window.location.href = '/manager/absence_back'
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* SIDE BAR */}
      {<KahHeaderManager/>}
      {/* MAIN CONTENT */}
      <div style={{ flex: 1 }}>
      {/* logo */}
      <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
      {/* content */}
      <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
              <h2>請假後台</h2>
              <p>共生製作-員工請假通知後台</p>
              <table class="table" >
                <thead>
                  <tr>
                    <th scope="col">員工姓名</th>
                    <th scope="col">假別</th>
                    <th scope="col">請假起訖</th>
                    <th scope="col">請假日數</th>
                    <th scope="col">假由</th>
                    <th scope="col">請假通知</th>
                  </tr>
                </thead>
                <tbody class="table-group-divider">
                  {absence_check.map((perm, index) => (
                    <tr key={index}>
                      <td>{perm.employee_name}</td>
                      <td>{perm.type_of_leave}</td>
                      <td>
                        {new Date(perm.start_time).toLocaleDateString()} 
                        {' '} {new Date(perm.start_time).toLocaleTimeString()} - 
                        {new Date(perm.end_time).toLocaleDateString()} 
                        {' '} {new Date(perm.end_time).toLocaleTimeString()}
                      </td>
                      <td>{calculateLeaveDays(perm.start_time, perm.end_time)}</td>
                      <td>{perm.reason}</td>
                      <td>
                        <div>
                          <Button className="me-1" variant="success" size="sm" onClick={() => handleApproval(perm.id, true)}>同意</Button>
                          <Button className="ms-1" variant="danger" size="sm" onClick={() => handleApproval(perm.id, false)}>不同意</Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="text-center">
                <Button className="mt-3" variant="danger" size="sm" onClick={Back_to_Absence}>返回</Button>
              </div>
          </div>
          
      </div>
        {/* Footer */}
      <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Absence_back_check
