//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React,{ useState, useEffect } from 'react'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaTrash } from 'react-icons/fa'; // 引入垃圾桶圖案
import Cookies from 'js-cookie';

//MaterialUI : https://mui.com/material-ui/getting-started/
import SaveIcon from '@mui/icons-material/Save';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//https://mui.com/material-ui/getting-started/templates/
//https://mui.com/x/react-data-grid/editing/#full-featured-crud

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";

function Manager() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768); // 響應式側邊欄
    const userData = useUserData(); // Cookies 取得userId、userName資料

    const [permissions, setPermissions] = useState([]);
    const [tempPermissions, setTempPermissions] = useState([]); // 暫存正在編輯的數據

    const [editingId, setEditingId] = useState(null);
    const [editingTable, setEditingTable] = useState(null);

    const [LinePermissionOptions, setLinePermissionOptions] = useState(["Internal", "Customer"]); // Line權限-選項(MemberPermission.line_permission)
    const [InternalPermissionOptions, setInternalPermissionOptions] = useState(["Staff", "HR","Boss"]); // 後台權限-選項(MemberPermission.line_permission)

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const PermissionLabels = {
        clock: '打卡',
        absence: '請假',
        repayment: '請款',
        salary: '薪資',
        schedule: '進度',
        wish: '日誌',
        repair: '報修'
    };

    const LineLabels = {
        '內部': ['Internal', 'Staff', 'HR', 'Boss', 'SYSAdmin'],
        '業主': ['Customer'],
    };

    const LinePermissionLabels = {
        SYSAdmin: '系統管理員',
        Boss: '管理者',
        HR: '人資',
        Staff: '員工',
        Customer: '業主',
        Other: '一般',
    };

    const allowedRoles = {
        features: [],
        line_permission:["Boss", "SYSAdmin", "HR"]
    };
    useEffect(() => {
        console.log("userData:", userData);
    }, [userData]);
    useCheckPermission(userData , allowedRoles, "/manager/");

    

    // 抓取"後端"的權限資料
    useEffect(() => {
        setLoading(true);
        axios.post('https://kah.dianalab.net/api/get-manager-permission')
            .then(response => {
                console.log(response.data);
                setPermissions(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                setError(error);
                setLoading(false);
            });
        // console.log(Cookies.get(user_id))
    }, []);

    // 按鈕: 新增
    function handleAddPermission() {
        window.location.href = '/manager/permission/add'; // 直接導向到指定URL
    }
    // 按鈕: 刪除
    function handleDeletePermission(id) {
        if (!id) {
            console.error('Invalid permission ID');
            return;
        }
        else{
            axios.put(`https://kah.dianalab.net/api/soft-delete-member-permission/${id}`)
            .then(response => {
                // 過濾掉被刪除的權限
                const updatedPermissions = permissions.filter(permission => permission.id !== id);
                setPermissions(updatedPermissions); // 更新狀態，觸發重新渲染
                console.log("Permission soft-deleted successfully.");
            })
            .catch(error => {
                console.error('Error soft-deleting permission: ', error);
            });
            // 重置編輯狀態
            setEditingId(null);
            setEditingTable(null);
        }
        
    }
    // 按鈕: 編輯
    const handleEditPermission = (id, table) => {
        setEditingId(id); // 設置當前編輯的索引
        setEditingTable(table); // 設置編輯的表格（'top' 或 'bottom'）
        setTempPermissions([...permissions]); // 初始化暫存數據（只更新當前行）
    };


    
    // 按鈕: 儲存
    const handleSavePermission = (id) => {
        // 在函數內定義 updatedPermission
        const updatedPermission = tempPermissions.find(permission => permission.id === id);
        
        if (!updatedPermission) {
            console.error(`Permission with ID ${id} not found in tempPermissions`);
            return; // 若未找到，直接退出函數
        }
    

        // 如果 line_permission 是 "Internal"，則採用後台權限的值
        if (updatedPermission.line_permission === "Internal") {
            updatedPermission.line_permission = InternalPermissionOptions[0];
        }
        // console.log('Updating permission:', updatedPermission);
        axios.put(`https://kah.dianalab.net/api/update-permission/${id}`, updatedPermission)
            .then(response => {
                console.log('Permission updated successfully.');
    
                // 更新主數據
                const updatedPermissions = permissions.map(permission =>
                    permission.id === id ? updatedPermission : permission
                );
                
                setPermissions(updatedPermissions);

                // 重置編輯狀態
                setEditingId(null);
                setEditingTable(null);
            })
            .catch(error => {
                console.error('Error updating permission:', error);
            });
    };
    

    const handleInputChange = (id, field, value) => {
        const updatedTempPermissions = tempPermissions.map(permission =>
            permission.id === id ? { ...permission, [field]: value } : permission
        );
        setTempPermissions(updatedTempPermissions);
    };
    

    const handleCheckboxChange = (id, field) => {
        const updatedTempPermissions = tempPermissions.map(permission =>
            permission.id === id
                ? {
                      ...permission,
                      [field]: permission[field] === 1 ? 0 : 1, // 勾選為 1，取消為 0
                  }
                : permission
        );
        setTempPermissions(updatedTempPermissions);
    };
    
    
    const handleCancelEdit = () => {
        setEditingId(null);
        setEditingTable(null);
        setTempPermissions([...permissions]);
    };
    

    if (loading) return <div></div>;//<p>Loading...</p>
    // if (error) return <p>Error loading permissions!</p>;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* SIDE BAR */}
            {<KahHeaderManager/>}
            
            {/* MAIN CONTENT */}
            <div style={{ flex: 1 }}>
                {/* Logo */}
                <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
                {/* Content */}
                <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                    <h2>人員權限管理</h2>
                    <p>共生製作-人員權限管理後台</p>
                    {/* 內部權限列表 */}
                    <h5 className='mt-4'>內部權限</h5>
                    <table className="table" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                        <thead>
                            <tr>
                            <th scope="col" style={{ width: '10%' }}>Line姓名</th>
                            <th scope="col" style={{ width: '10%', textAlign: 'start'  }}>Line權限</th>
                            <th scope="col" style={{ width: '70%', textAlign: 'start' }}>後台權限</th>
                            <th scope="col" style={{ width: '10%', textAlign: 'center' }}>權限編輯</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(editingTable === 'top' ? tempPermissions : permissions)
                            // .filter((permission) => 
                            //     (editingId === permission.id && editingTable === 'top') || 
                            //     LineLabels['內部'].includes(permission.line_permission)
                            // )
                            .map((permission) => (
                                <tr key={permission.id}>
                                    {/* Line姓名 */}
                                    <td>{permission.employee_name}</td>
                                    {/* Line權限 */}
                                    <td>
                                        {editingId === permission.id ? (
                                            <Form.Select
                                                value={permission.line_permission}
                                                onChange={(e) => handleInputChange(permission.id, 'line_permission', e.target.value)}
                                            >
                                                {LinePermissionOptions.map((option, i) => {
                                                    // 根據 LineLabels 製作選項。如果找不到對應的標籤，就顯示原始值
                                                    const label = Object.keys(LineLabels).find((category) =>
                                                        LineLabels[category].includes(option)
                                                    ) || LinePermissionLabels[option] || option;
                                                    return (
                                                        <option key={i} value={option}>
                                                            {label}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        ) : (
                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                <span>
                                                    {Object.keys(LineLabels).find((category) =>
                                                        LineLabels[category].includes(permission.line_permission)
                                                    ) || LinePermissionLabels[permission.line_permission] || permission.line_permission}
                                                </span>
                                                <span>-</span>
                                                <span>
                                                    {LinePermissionLabels[permission.line_permission] || '未定義'}
                                                </span>
                                            </div>
                                        )}
                                        
                                    </td>
                                    {/* 後台權限 */}
                                    <td>
                                        {editingId === permission.id && permission.line_permission !== 'Customer'? (
                                            <Form.Select
                                                value={permission.line_permission}
                                                onChange={(e) => handleInputChange(permission.id, 'line_permission', e.target.value)}
                                            >
                                                {InternalPermissionOptions.map((option, i) => (
                                                    <option key={i} value={option}>
                                                        {LinePermissionLabels[option] || option}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        ) : (
                                            null
                                        )}
                                        
                                        <FormGroup style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                            {['clock', 'absence', 'repayment', 'salary', 'schedule', 'wish', 'repair'].map((field) => (
                                                <FormControlLabel
                                                    key={field}
                                                    control={
                                                        <Checkbox
                                                            checked={permission.line_permission === 'Customer' ? false : !!permission[field]}
                                                            onChange={() => handleCheckboxChange(permission.id, field)}
                                                            disabled={editingId !== permission.id || permission.line_permission === 'Customer'}
                                                        />
                                                    }
                                                    label={PermissionLabels[field]} // 顯示對應的中文標籤
                                                />
                                            ))}
                                        </FormGroup>
                                    </td>
                                    {/* 權限編輯 */}
                                    <td style={{ verticalAlign: 'middle'}}>
                                        <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                                            {editingId === permission.id ? (
                                                <>
                                                <Button className='me-2' variant="primary" size="sm" onClick={() => handleSavePermission(permission.id)}>
                                                    <SaveIcon /> {/* SaveIcon */}
                                                </Button>

                                                <FaTrash
                                                    className='ms-2'
                                                    style={{ color: 'gray', cursor: 'pointer' }}
                                                    onClick={() => handleDeletePermission(permission.id)}
                                                /> {/* FaTrashIcon */}
                                                </>
                                            ) : (
                                                <Button variant="outline-dark" size="sm" onClick={() => handleEditPermission(permission.id, 'top')}>
                                                    編輯
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {permissions.filter((permission) => LineLabels['內部'].includes(permission.line_permission)).length === 0 && (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center', color: 'gray' }}>
                                        暫無資料
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    
                    {/* 業主權限列表 */}
                    <h5 className='mt-4'>業主權限</h5>
                    <table className="table" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                        <thead>
                            <tr>
                            <th scope="col" style={{ width: '10%' }}>Line姓名</th>
                            <th scope="col" style={{ width: '10%', textAlign: 'start' }}>Line權限</th>
                            <th scope="col" style={{ width: '70%', textAlign: 'start' }}>後台權限</th>
                            <th scope="col" style={{ width: '10%', textAlign: 'center' }}>權限編輯</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(editingTable === 'bottom' ? tempPermissions : permissions)
                            .filter((permission) => 
                                (editingId === permission.id && editingTable === 'bottom') || 
                                LineLabels['業主'].includes(permission.line_permission)
                            )
                            .map((permission) => (
                                <tr key={permission.id}>
                                    {/* Line姓名 */}
                                    <td>{permission.employee_name}</td> 
                                    {/* Line權限 */}
                                    <td>
                                        {editingId === permission.id ? (
                                            <Form.Select
                                                value={permission.line_permission}
                                                onChange={(e) => handleInputChange(permission.id, 'line_permission', e.target.value)}
                                            >
                                                {LinePermissionOptions.map((option, i) => {
                                                    // 根據 LineLabels 製作選項。如果找不到對應的標籤，就顯示原始值
                                                    const label = Object.keys(LineLabels).find((category) =>
                                                        LineLabels[category].includes(option)
                                                    ) || LinePermissionLabels[option] || option;
                                                    return (
                                                        <option key={i} value={option}>
                                                            {label}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        ) : (
                                            Object.keys(LineLabels).find((category) =>
                                                LineLabels[category].includes(permission.line_permission)
                                            ) || LinePermissionLabels[permission.line_permission] || permission.line_permission
                                        )}
                                    </td>
                                    {/* 後台權限 */}
                                    <td>
                                        {editingId === permission.id && permission.line_permission !== 'Customer'? (
                                            <Form.Select
                                                value={permission.line_permission}
                                                onChange={(e) => handleInputChange(permission.id, 'line_permission', e.target.value)}
                                            >
                                                {InternalPermissionOptions.map((option, i) => (
                                                    <option key={i} value={option}>
                                                        {LinePermissionLabels[option] || option}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        ) : (
                                            null
                                        )}
                                        <FormGroup style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                            {['clock', 'absence', 'repayment', 'salary', 'schedule', 'wish', 'repair'].map((field) => (
                                                <FormControlLabel
                                                    key={field}
                                                    control={
                                                        <Checkbox
                                                            checked={permission.line_permission === 'Customer' ? false : !!permission[field]}
                                                            onChange={() => handleCheckboxChange(permission.id, field)}
                                                            disabled={editingId !== permission.id || permission.line_permission === 'Customer'}
                                                        />
                                                    }
                                                    label={
                                                        PermissionLabels[field]} // 顯示對應的中文標籤
                                                />
                                            ))}
                                        </FormGroup>
                                    </td>
                                    {/* 權限編輯 */}
                                    <td style={{ verticalAlign: 'middle'}}>
                                        <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                                            {editingId === permission.id ? (
                                                <>
                                                <Button className='me-2' variant="primary" size="sm" onClick={() => handleSavePermission(permission.id)}>
                                                    <SaveIcon /> {/* SaveIcon */}
                                                </Button>

                                                <FaTrash
                                                    className='ms-2'
                                                    style={{ color: 'gray', cursor: 'pointer' }}
                                                    onClick={() => handleDeletePermission(permission.id)}
                                                /> {/* FaTrashIcon */}
                                                </>
                                            ) : (
                                                <Button variant="outline-dark" size="sm" onClick={() => handleEditPermission(permission.id, 'bottom')}>
                                                    編輯
                                                </Button>
                                            )}
                                            
                                        </div>
                                    </td>

                                </tr>
                            ))}
                            {permissions.filter((permission) => LineLabels['業主'].includes(permission.line_permission)).length === 0 && (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center', color: 'gray' ,paddingRight: '40px'}}>
                                        暫無資料
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    

                    <div className="text-center">
                    {editingId === null ? (
                        <Button className="me-4 mt-3" variant="success" size="sm" onClick={handleAddPermission}>
                            新增
                        </Button>
                    ) : (
                        <Button className="me-4 mt-3" variant="danger" size="sm" onClick={handleCancelEdit}>
                            取消
                        </Button>
                    )}
                    </div>
                    
                </div>
                

            </div>
            {/* footer */}
            <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        </div>
    );
}

export default Manager
