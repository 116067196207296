import React from 'react'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件

import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'
import MUISelection from './components/MUISelection'

function repayment_search() {
    function handleClick() {
        window.location.href = 'http://127.0.0.1:8000/repaymentGetData';
    }
    return (
        <div text-center mt-3>
            <KahHeader/>
            <MUISelection/>
            <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" onClick={handleClick}>查詢</Button></div>
            <KahFooter/>
        </div>
    )
}

export default repayment_search
