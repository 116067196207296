import React, { useState, useEffect } from 'react';

function MonthSwitch({ onMonthChange }) {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    // 格式化當前月份為 YYYY-MM 並傳遞給父組件
    const formattedMonth = currentMonth.toISOString().slice(0, 7);
    // console.log("Formatted month:", formattedMonth);  // 新增 log 確認日期
    onMonthChange(formattedMonth);
  }, [currentMonth, onMonthChange]);

  const handlePrevMonth = () => {
    // 修改：在減少月份時，使用正確的月份邏輯
    const prevMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() - 1));
    console.log("Previous month:", prevMonth);  // 新增 log 確認上一月的計算
    setCurrentMonth(prevMonth);
  };

  const handleNextMonth = () => {
    // 修改：在增加月份時，使用正確的月份邏輯
    const nextMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
    console.log("Next month:", nextMonth);  // 新增 log 確認下一月的計算
    setCurrentMonth(nextMonth);
  };

  const formatMonth = currentMonth.toLocaleString('zh-TW', { year: 'numeric', month: 'long' });

  return (
    <div className="month-switcher justify-content-start mt-1">
      <button onClick={handlePrevMonth}>&lt;</button>
      <span>{formatMonth}</span>
      <button onClick={handleNextMonth}>&gt;</button>
    </div>
  );
}

export default MonthSwitch;
