//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';

//MaterialUI : https://mui.com/material-ui/getting-started/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo2'
import MonthSwitch from '../components/MonthSwitch'
function Repayment_back_search() {
    const [currentMonth, setCurrentMonth] = useState('');  // 狀態保存選擇的月份
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
    const [isMobile, setIsMobile] = useState(false);  // 手機與電腦

    // 根據視窗大小監控是否為手機視圖
    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
            setIsMobile(true);
            setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
        } else {
            setIsMobile(false);
            setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function Back_to_Salary(){
        window.location.href = '/manager/repayment_back'
    }
    
    function Go_to_Repayment(){
        window.location.href = '/repayment'
    }

    const handleMonthChange = (month) => {
        // 僅當月份更改時，發送請求
        if (month !== currentMonth) {
          setCurrentMonth(month);
          console.log('Month updated to:', month);
        }
      };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        {<KahHeaderManager/>}
  
        {/* MAIN CONTENT */}
        <div style={{ flex: 1 }}>
          {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
  
          {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                    <h2>請款後台</h2>
                    <p>共生製作-代墊紀錄表後台</p>
                    <div className='d-flex align-items-center'>
                    <MonthSwitch onMonthChange={handleMonthChange} />  {/* 傳入 handleMonthChange */}
                        <h5 className='mt-2'>請款紀錄</h5>
                    </div>
                    <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">請款人</th>
                        <th scope="col">部門</th>
                        <th scope="col">購買日期</th>
                        <th scope="col">分類</th>
                        <th scope="col">案號</th>
                        <th scope="col">內容</th>
                        <th scope="col">備註</th>
                        <th scope="col">合計</th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr>
                            <th scope="row" >員工1</th>
                            <td>設計</td>
                            <td>2024-05-01</td>
                            <td>xx</td>
                            <td>xx</td>
                            <td>xx</td>
                            <td>xx</td>
                            <td>xx</td>
                        </tr>
                        <tr>
                            <th scope="row" >員工1</th>
                            <td>設計</td>
                            <td>2024-05-02</td>
                            <td>xx</td>
                            <td>xx</td>
                            <td>xx</td>
                            <td>xx</td>
                            <td>xx</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div className="text-center">
                        <Button className="ms-4 mb-2" variant="success" size="sm" onClick={Go_to_Repayment}>匯出月支出代墊款</Button>
                </div>
                <div className="text-center">
                        <Button className="ms-4 mt-3" variant="danger" size="sm" onClick={Back_to_Salary}>返回</Button>
                </div>
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Repayment_back_search
