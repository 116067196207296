import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import { Stack } from 'react-bootstrap';

import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'


function Notes({ data, photo, description }) {
    return (
        <Stack className="notes-info mt-4 ms-3">
          <h5>{data}</h5>
          <h5>施工照片：</h5>
          {photo && <img src={photo} alt="施工照片" className="img-fluid mb-3 me-3" style={{ maxWidth: "100%" }}/>} {/* 假設 photo 是圖片路徑 */}
          <h5>施工進度說明：</h5>
          <p className='text-start mt-3'>{description}</p>
        </Stack>
      );
  }

function Schedule(){
    return(
    <div>
        <KahHeader title = "進度"/>
        <h4 className='text-center'>共生室內設計專案</h4>
        <Notes
            data="2024年2月29日 基礎灌漿作業"
            photo="/IMAG1086.jpg"
            description="料羅工地混凝土灌漿"
        />

        <Notes
            data="2024年1月29日 石材切割"
            photo="/IMAG0960.jpg"
            description="前往泉州進行石材廠驗"
        />

        <KahFooter/>
    </div>
    )
}

export default Schedule