// React、materialUI
import React,{useState} from 'react';
import Button from '@mui/material/Button';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';

//components
import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'


function GoogleCalendar() {
  const iframeSrc = "https://calendar.google.com/calendar/embed?src=5b634925e73d8f93a4b90279689c99646fb292e17dcf60f38dcfc64e36ffa486%40group.calendar.google.com&ctz=Asia%2FTaipei";
  
  const calendarContainerStyle = {
    maxWidth: '1000px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: 'white',
  };

  const iframeStyle = {
    border: 'none',
    borderRadius: '3px', // 設置圓角
  };
  
  return (
    <div className="google-calendar-container" style={calendarContainerStyle}>
      <iframe 
        src={iframeSrc} 
        style={iframeStyle}
        width="100%" 
        height="600" >
      </iframe>
    </div>
  );
}

function AbsenceForm(){
    function handleClick() {
      window.location.href = 'https://forms.gle/JoJbXK1UQKmNyrxTA';
  }
  return(
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Button variant="contained" endIcon={<ContentPasteGoIcon />} onClick={handleClick}>
      請假表單
    </Button>
  </div>
  );
}

function Absence() {
  return (
    <div>
      <KahHeader title="請假"/>
      <GoogleCalendar />
      <AbsenceForm/>
      <KahFooter/>
    </div>
  );
}




export default Absence;