import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

//KAH components
import KahHeaderManager from '../components/KahHeaderManager';
import KahHeaderLogo from '../components/KahHeaderLogo';
import KahFooter from '../components/KahFooter';
import MonthSwitch from '../components/MonthSwitch';
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";

function ClockBack() {
  const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
  const userData = useUserData(); // Cookies 取得userId、userName資料
  const [clockRecords, setClockRecords] = useState([]);
  const [currentMonth, setCurrentMonth] = useState('');

  // 檢查權限
  const allowedRoles = {
    features: ['clock'],
    line_permission:["SYSAdmin", "Boss", "HR", 'Staff']
  };
  const hasPermission = useCheckPermission(userData , allowedRoles, "/manager/"); 
  // console.log('hasPermission:', hasPermission); 
  
  const handleMonthChange = (month) => {
    // 僅當月份更改時，發送請求
    if (month !== currentMonth) {
      setCurrentMonth(month);
    }
  };

  useEffect(() => {
    if (currentMonth && hasPermission !== null) {
        if (hasPermission) {
            // 如果有權限，發送完整的 clock records 請求
            axios.post('https://kah.dianalab.net/api/get-clock-records', { month: currentMonth })
                .then(response => {
                    setClockRecords(response.data);
                    console.log('Clock records:', response.data);
                })
                .catch(error => {
                    console.error('Error fetching clock records:', error);
                });
        } else {
            // 如果沒有權限，發送限制的 clock records self 請求
            axios.post('https://kah.dianalab.net/api/get-clock-records-self', { month: currentMonth, userId: userData.userId })
                .then(response => {
                    setClockRecords(response.data);
                    console.log('Self clock records:', response.data);
                })
                .catch(error => {
                    console.error('Error fetching self clock records:', error);
                });
        }
    }
  }, [hasPermission, currentMonth]);

  // 打卡至 Line 的處理函式
  const handleLineClock = () => {
    window.location.href = '/clock';
  };

  // 確認打卡地點的處理函式
  const handleClockLocation = () => {
    window.location.href = '/manager/clock_back/location';
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* SIDE BAR */}
      {<KahHeaderManager/>}

      {/* MAIN CONTENT */}
      <div style={{ flex: 1 }}>
        {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

        {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
          <h2>打卡後台</h2>
          <div className='d-flex align-items-center'>
            <MonthSwitch onMonthChange={handleMonthChange} />
            <h5 className='mt-2'>出勤紀錄</h5>
          </div>
          <table className="table" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
            <thead>
              <tr>
                <th scope="col" style={{ width: '20%' }}>員工姓名</th>
                <th scope="col" style={{ width: '20%' }}>出勤天數</th>
                <th scope="col" style={{ width: '20%' }}>加班時數4/3</th>
                <th scope="col" style={{ width: '20%' }}>加班時數5/3</th>
                <th scope="col" style={{ width: '20%' }}>出勤紀錄表</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {clockRecords.length > 0 ? (
                clockRecords.map((record, index) => (
                  <tr key={index}>
                    <th scope="row">{record.employee_name}</th>
                    <td>{record.attendance_days}</td>
                    <td>{record.overtime_hours_4_3}</td>
                    <td>{record.overtime_hours_5_3}</td>
                    <td><Link to={`../manager/clock_back/search?employee=${record.employee_name}`} className="btn btn-outline-dark btn-sm">查詢</Link></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">暫無資料</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="text-center d-flex flex-column ms-1">
                <Button className="mt-1" style={{ width: '200px' }} variant="outline-primary" size="sm" onClick={handleLineClock}>
                    Line打卡
                </Button>
                <Button className="mt-3" style={{ width: '200px' }} variant="outline-success" size="sm" onClick={handleClockLocation}>
                    打卡地點
                </Button> 
          </div>
        </div>
      </div>
      {/* Footer */}
      <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  );
}

export default ClockBack;
