import React,{useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import { Stack } from 'react-bootstrap';
import './salary.css';
import { useNavigate } from 'react-router-dom';

import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'

  function MonthSwitch() {
    const [currentMonth, setCurrentMonth] = useState(new Date()); // 當前月份的狀態

    // 函數：切換到上一個月
    const handlePrevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
    };

    // 函數：切換到下一個月
    const handleNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
    };

    // 格式化顯示當前月份
    const formatMonth = currentMonth.toLocaleString('zh-TW', { year: 'numeric', month: 'long' });

    return (
        <div className="d-flex month-switcher justify-content-center align-items-center mb-3">
            <button onClick={handlePrevMonth}>&lt;</button> {/* 左箭頭 */}
            <span>{formatMonth}</span> {/* 顯示當前月份 */}
            <button onClick={handleNextMonth}>&gt;</button> {/* 右箭頭 */}
        </div>
    );
}
  
function Staff({ name, salaryComponents, estimatedSalary }) {
    return (
      <Stack className='staff-info'>
        <h5>姓名：{name}</h5>
        <h5>薪資組成：{salaryComponents}</h5>
        <h5>薪資估算：{estimatedSalary}</h5>
      </Stack>
    );
  }
  
  function StaffSalarly({ baseSalary, advancePayment, overtimePay, leaveDeduction, bonus }) {
    return (
      <Stack className='staff-info'>
        <h5>本薪：{baseSalary}</h5>
        <h5>代墊款：{advancePayment}</h5>
        <h5>加班費：{overtimePay}</h5>
        <h5>請假扣款：{leaveDeduction}</h5>
        <h5>獎金：{bonus}</h5>
      </Stack>
    );
  }

  function Salarly(){
    let navigate = useNavigate();

    function handleClick() {
        navigate('/salarlylaw');
    }

    return(
    <div className="container"> {/* 添加 container 類別來控制最大寬度並居中對齊 */}
        <KahHeader title = "薪資"/>
        <MonthSwitch/>
        <div className="row justify-content-center"> {/* 添加 row 和 justify-content-center 類別來水平居中對齊 */}
            <div className="col-md-5"> {/* 控制列的寬度 */}
                <Staff 
                    name="員工1" 
                    salaryComponents="本薪+代墊款+加班費+獎金-請假扣款" 
                    estimatedSalary="NT$50,000" 
                />
                <hr className="hr-style-1" />
                <h5 className='text-center mt-2 mb-3'>薪資細流</h5>
                <StaffSalarly 
                    baseSalary="NT$ 40,000" 
                    advancePayment="NT$ 10,000" 
                    overtimePay="NT$ 5,000" 
                    leaveDeduction="NT$ -2,000" 
                    bonus="NT$ 3,000" 
                />
                <div className="d-flex justify-content-end"> {/* 使用 Flexbox 進行對齊 */}
                    {/* <Button className='btn btn-success' onClick={handleClick}>勞基法 <i className="fa fa-arrow-right"></i></Button> 使用 HTML 實體表示箭頭 */}
                </div>
            </div>

        </div>
        <KahFooter/>
    </div>
    )
}


export default Salarly