//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import { Form, Button} from 'react-bootstrap';
import axios from 'axios';
//MaterialUI : https://mui.com/material-ui/getting-started/
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

//line-liff : https://developers.line.biz/en/docs/liff/overview/
import liff from '@line/liff'; 

//KAH components
import KahHeaderLogo from './components/KahHeaderLogo'

const LIFFID = '2005573301-2V4yYN0w';

function AbsenceForm() {
    // 當前日期
    const now = new Date();
    const options = { timeZone: 'Asia/Taipei', year: 'numeric', month: '2-digit', day: '2-digit' };
    const todayDate = now.toLocaleDateString('en-CA', options); // 格式化為 YYYY-MM-DD
    const defaultStartTime = `${todayDate}T08:30`;  // "YYYY-MM-DDTHH:MM"
    const defaultEndTime = `${todayDate}T17:30`;  // "YYYY-MM-DDTHH:MM"
    // 使用 useState 來設定預設值
    const [startDate, setStartDate] = useState(defaultStartTime); //請假(起)
    const [endDate, setEndDate] = useState(defaultEndTime); //請假(迄)
    const [loading, setLoading] = useState(false); // 載入狀態
    const [success, setSuccess] = useState(false); // 載入成功
    const [formData, setFormData] = useState({
        name: '',
        leaveType: '',
        reason: '',
        startDate: '',
        endDate: ''
    });
    const timerRef = React.useRef();


    // 初始化 LIFF 並獲取使用者資料
    useEffect(() => {
        liff.init({ liffId: LIFFID }) // 替換為你的 LIFF ID
            .then(() => {
                if (!liff.isLoggedIn()) {
                    liff.login(); // 如果未登入則要求登入
                } else {
                    liff.getProfile()
                        .then(profile => {
                            const displayName = profile.displayName; // 取得 LINE 名稱
                            console.log('使用者名稱：', displayName);
                            // 將名稱填入表單
                            setFormData(prevData => ({
                                ...prevData,
                                name: displayName
                            }));
                        })
                        .catch(err => {
                            console.error('抓取使用者資料失敗', err);
                        });
                }
            })
            .catch(err => {
                console.error('LIFF 初始化失敗', err);
            });
    }, []);

    //請假(起/迄)
    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    // 載入狀態
    const handleClose = () => {
        setLoading(false);
        setSuccess(false);
    };

    // 處理資料輸入
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    // 處理提交
    const handleSubmit = (event) => {
        event.preventDefault();
    
        // 檢查所有欄位是否填寫
        if (!formData.name || !formData.leaveType || formData.leaveType === "選擇假別" || !formData.reason || !startDate || !endDate) {
            alert("所有欄位都必須填寫！");
            return;
        }
    
        setLoading(true);
        setSuccess(false);
    
        // 設定要發送的數據
        const payload = {
            name: formData.name,
            type_of_leave: formData.leaveType,
            reason: formData.reason,
            start_time: startDate,  // 確保日期格式為完整 ISO 8601 格式
            end_time: endDate  // 確保日期格式為完整 ISO 8601 格式
        };
        
        // 使用 fetch API 發送 POST 請求
        console.log('Payload:', payload);
        axios.post('https://kah.dianalab.net/api/creat-absence', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            // Axios 不需要手動檢查 response.ok，會自動進入 catch 處理錯誤
            console.log('Success:', response.data);
            setLoading(false);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false); // Optionally hide success message after a delay
            }, 3000);  // Hide success message after 3 seconds
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);
            alert('提交失敗，請稍後再試！');
        });
        
    };
    

    React.useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return (
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <Form className="mb-3" onSubmit={handleSubmit}>
                <KahHeaderLogo/>
                {/*   */}

                <Form.Group controlId="formBasicName">
                    <Form.Label>請假人</Form.Label>
                    <Form.Control type="text" placeholder="請輸入您的名字" id="name" value={formData.name} onChange={handleInputChange} readOnly/>
                </Form.Group>


                <Form.Group className="mb-2" controlId="formBasicLeaveType">
                    <Form.Label>假別</Form.Label>
                    <Form.Select id="leaveType" value={formData.leaveType} onChange={handleInputChange}>
                        <option>選擇假別</option>
                        <option value="01">01-特休假</option>
                        <option value="02">02-加班轉補休</option>
                        <option value="03">03-病假</option>
                        <option value="04">04-生理假</option>
                        <option value="05">05-公假</option>
                        <option value="06">06-事假</option>
                        <option value="07">07-婚假</option>
                        <option value="08">08-喪假</option>
                        <option value="09">09-產假</option>
                        <option value="10">10-產檢假</option>
                        <option value="11">11-陪產假</option>
                        <option value="19">19-其他假</option>
                        <option value="20">20-陽光假(天)</option>
                        <option value="21">21-共生公出(時)</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-2" controlId="formBasicReason">
                    <Form.Label>假由</Form.Label>
                    <Form.Control type="text" placeholder="請描述假由" id="reason" value={formData.reason} onChange={handleInputChange} required/>
                </Form.Group>

                <Form.Group className="mb-2" controlId="formBasicStartDate">
                    <Form.Label>請假(起)</Form.Label>
                    <Form.Control
                        type="datetime-local"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                </Form.Group>

                <Form.Group className="mb-2" controlId="formBasicEndDate">
                    <Form.Label>請假(訖)</Form.Label>
                    <Form.Control
                        type="datetime-local"
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </Form.Group>

                <div className="d-flex justify-content-center">
                    <Button variant="success" className="mt-3" type="submit">
                        提交
                    </Button>
                </div>

            </Form>

            {/* Material-UI Dialog with Progress Indicator */}
            <Dialog open={loading || success} onClose={handleClose}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        {loading && <CircularProgress size={68} sx={{ color: green[500] }} />}
                        {success && (
                            <>
                                <CheckIcon sx={{ color: green[500], fontSize: 60 }} />
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    成功提交
                                </Typography>
                            </>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AbsenceForm
