import React,{useState} from 'react'
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Nav from 'react-bootstrap/Nav';

import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'

function Repair(){
    return(
        <div>
        <KahHeader title = "報修"/>
        <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>報修人</th>
                <th>手機</th>
                <th>報修項目</th>
                <th>其他說明</th>
                <th>報修紀錄</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>李仁傑</td>
                <td>0966687xxx</td>
                <td>電視桌裂痕</td>
                <td>無</td>
                <td><a href="https://www.example.com">連結</a></td>
                </tr>
            </tbody>
        </Table>

        <Nav className="d-flex justify-content-end">
            <Nav.Link href="https://docs.google.com/spreadsheets/d/1P0y7vFGe4dHdj20S6vsaCx9TanZ7cpZ8VdAd0CEKPlI/edit?resourcekey#gid=326793876">前往表單></Nav.Link>
        </Nav>
        <KahFooter/>
    </div>
    )
}

export default Repair;