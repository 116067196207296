import React, { Component } from 'react';

export class KahFooter extends Component {
  render() {
    const { isSidebarOpen, isMobile } = this.props; // 接收來自父組件的屬性

    return (
      <div className="container-fluid">
        <footer
          style={{
            position: 'relative',
            bottom: 0,
            left: isSidebarOpen && !isMobile ? '112px' : '0', // 根據側邊欄狀態動態調整位置
            width: '100%',
            transition: 'left 0.3s ease', // 增加過渡效果
          }}
        >
          <ul className="nav justify-content-center border-bottom pb-1 mb-1"></ul>
          <p className="text-center text-muted mb-4" style={{ whiteSpace: 'nowrap' }}>
            &copy; KAH Design 共生製作+知光合禾建築師事務所
          </p>
        </footer>
      </div>
    );
  }
}

export default KahFooter;
