import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Home'; // './Home' 是相對於 App.js 的路徑
import Home_back from './Home_back'; 

import Clock from './clock';
import ClockDelete from './clock_delete';
import Repair from './repair';
import RepairForm from './repair_form.js';
import RepaymentSearch from './repayment_search';
import Repayment from './repayment';
import RepaymentForm from './repayment_form';
import Wish from './wish';

import Absence from './absence.js';
import AbsenceForm from './absence_form';
import Salary from './salary';
import SalaryLaw from './salarylaw';
import Schedule from './schedule';
import ScheduleAdd from './scheduleadd';
import Work_overtime_form from './work_overtime_form';


import HomeBack from './back-manager/home_back';
import Manager from './back-manager/manager_back';
import ManagerAdd from './back-manager/manager_back_add';
import Employee from './back-manager/employee_back.js';
import EmployeeAdd from './back-manager/employee_back_add.js';
import AbsenceBack from './back-manager/absence_back';
import AbsenceCard from './back-manager/absence_card';
import AbsenceEdit from './back-manager/absence_back_edit';
import AbsenceCheck from './back-manager/absence_back_check';
import ClockCheck from './back-manager/clock_back.js';
import ClockSearch from './back-manager/clock_back_search.js';
import ClockLocation from './back-manager/clock_back_location.js';
import ClockAdd from './back-manager/clock_back_add.js';
import SalaryBack from './back-manager/salary_back.js';
import SalaryBackEdit from './back-manager/salary_back_edit.js';
import RepaymentBack from './back-manager/repayment_back.js';
import RepaymentBackSearch from './back-manager/repayment_back_search.js';
import ScheduleBack from './back-manager/schedule_back.js';
import ScheduleBackAdd from './back-manager/schedule_back_add.js';
import WishBack from './back-manager/wish_back.js';
import RepairBack from './back-manager/repair_back.js';
import Projectback from './back-manager/project_back.js';

function App() {
  return (
    <Router>
      <div className="App" style={{ fontFamily: "'PingFang TC', 'Noto Sans TC', sans-serif" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/back/test' element={<Home_back/>}/>

          <Route path="/manager" element={<HomeBack />} />
          <Route path="/clock" element={<Clock />} />
          <Route path="/repair" element={<Repair />} />
          <Route path="/repair_form" element={<RepairForm />} />
          <Route path="/repayment_search" element={<RepaymentSearch />} />
          <Route path="/repayment" element={<Repayment />} />
          <Route path="/repayment_form" element={<RepaymentForm />} />
          <Route path="/wish" element={<Wish />} />
          <Route path="/absence" element={<Absence />} />
          <Route path="/absence_form" element={<AbsenceForm />} />
          <Route path="/salary" element={<Salary />} />
          <Route path="/salarylaw" element={<SalaryLaw />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/scheduleadd" element={<ScheduleAdd />} />
          <Route path="/clock_delete" element={<ClockDelete />} />
          <Route path="/work_overtime_form" element={<Work_overtime_form />} />
          
          
         
          <Route path="/manager/employee" element={<Employee />} />
          <Route path="/manager/employee/add" element={<EmployeeAdd />} />
          <Route path="/manager/permission/add" element={<ManagerAdd />} />
          <Route path="/manager/permission" element={<Manager />} />
          <Route path="/manager/absence_back" element={<AbsenceBack />} />
          <Route path="/manager/absence_back/card" element={<AbsenceCard />} />
          <Route path="/manager/absence_back/edit" element={<AbsenceEdit />} />
          <Route path="/manager/absence_back/check" element={<AbsenceCheck />} />
          <Route path="/manager/clock_back" element={<ClockCheck />} />
          <Route path="/manager/clock_back/search" element={<ClockSearch />} />
          <Route path="/manager/clock_back/location" element={<ClockLocation />} />
          <Route path="/manager/clock_back/add" element={<ClockAdd />} />
          <Route path="/manager/salary_back" element={<SalaryBack />} />
          <Route path="/manager/salary_back/edit" element={<SalaryBackEdit />} />
          <Route path="/manager/repayment_back" element={<RepaymentBack />} />
          <Route path="/manager/repayment_back/search" element={<RepaymentBackSearch />} />
          <Route path="/manager/schedule_back" element={<ScheduleBack />} />
          <Route path="/manager/schedule_back/add" element={<ScheduleBackAdd />} />
          <Route path="/manager/wish_back" element={<WishBack />} />
          <Route path="/manager/repair_back" element={<RepairBack />} />
          <Route path="/manager/project_back" element={<Projectback />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
