import React from 'react'

function KahHeaderLogo({ isSidebarOpen, isMobile, style }) {
  const marginLeft = isSidebarOpen && !isMobile ? '250px' : '10px';
  return (
    <div className="text-center mt-4 mb-3" style={{ marginLeft }}>
        <img src="/KAH_Design_logo.png" alt="KAH Design Logo" 
        style={{ 
          height: 'auto', 
          width: '300px', 
          maxWidth: '100%', 
          marginLeft: '-30px',
          ...style, // 合併傳入的 style
          }} />
        {/* <img src="../KAH_Design_logo.png" alt="KAH Design Logo" style={{ height: 'auto', width: '300px', maxWidth: '100%' , marginLeft: '-100px'}} /> */}
    </div>
  )
  
}

export default KahHeaderLogo
