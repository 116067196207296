import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import { Button, Modal, Form } from 'react-bootstrap';

//MaterialUI : https://mui.com/material-ui/getting-started/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeaderLogo from '../components/KahHeaderLogo'
import KahFooter from '../components/KahFooter'
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';

function ClockBackLocation() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
    const [locations, setLocations] = useState([]);
    const [editData, setEditData] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [newLocationData, setNewLocationData] = useState({
        id:'',
        location: '',
        location_type: '',
        location_address: '',
        lat: '',
        lon: ''
    });
    const [showAddModal, setShowAddModal] = useState(false);
    const [editId, setEditId] = useState(null); // 用于追踪当前编辑的地点ID
    const [deletedId, setDeletedId] = useState(null); // 用于追踪被删除的地点ID

    useEffect(() => {
        if (editId !== null) {
            setLocations(currentLocations => currentLocations.map(loc => loc.id === editData.id ? { ...loc, ...editData } : loc));
            setEditId(null);  // 重置 editId 以避免重复更新
        }
    }, [editId]);
    useEffect(() => {
        if (deletedId !== null) {
            setLocations(currentLocations => currentLocations.filter(location => location.id !== deletedId));
            setDeletedId(null);  // 重置 deletedId 以避免重复更新
        }
    }, [deletedId]);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const url = `https://kah.dianalab.net/api/manager/clock_location`;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data)
                setLocations(data);
            } catch (error) {
                console.error('Failed to fetch locations:', error);
            }
        };

        fetchLocations();
    }, []);

    const handleEdit = (location) => {
        console.log("Editing location:", location); // 可以幫助確認location數據結構
        setEditData(location);
        setShowEditModal(true);
    };
    const handleChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        setShowEditModal(false);
    };
    const handleSubmit = async () => {
        console.log("editData",editData)
        if (!editData.id) {
            console.error('Error: Location ID is undefined.');
            return; // 阻止進一步執行
        }
        
        try {
            const response = await fetch(`https://kah.dianalab.net/api/update_location/${editData.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to update location');
            }
            const updatedLocations = locations.map(loc => loc.id === editData.id ? { ...loc, ...editData } : loc);
            setLocations(updatedLocations);
            setEditId(editData.id);
            setShowEditModal(false);
        } catch (error) {
            console.error('Failed to update location:', error);
        }
    };

    const handleDelete = async () => {
        console.log("editData",editData)
        try {
            const response = await fetch(`https://kah.dianalab.net/api/delete_location/${editData.id}`, {
                method: 'DELETE'
            });
            
            if (!response.ok) {
                throw new Error('Failed to delete location');
            }
            // 使用函數式更新來確保我們使用最新的狀態
            setDeletedId(editData.id);
            handleClose();  // 關閉模態框
            

        } catch (error) {
            console.error('Failed to delete location:', error);
            alert('Failed to delete location. Check the console for more details.');
        }
        
    };
    const handleAddNew = () => {
        setNewLocationData({
            id:'',
            location: '',
            location_type: '',
            location_address: '',
            lat: '',
            lon: ''
        });
        setShowAddModal(true);
    };
    
    const handleCloseAddModal = () => {
        setShowAddModal(false);
    };
    
    const handleNewLocationChange = (e) => {
        setNewLocationData({ ...newLocationData, [e.target.name]: e.target.value });
    };
    
    const handleAddNewLocation = async () => {
        console.log("newLocationData", newLocationData);
        // 确保数据类型正确
        const payload = {
            ...newLocationData,
            lat: parseFloat(newLocationData.lat),  // 确保纬度是浮点数
            lon: parseFloat(newLocationData.lon),  // 确保经度是浮点数
        };
    
        try {
            const response = await fetch(`https://kah.dianalab.net/api/add_location`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error('Failed to add new location: ' + (errorData.detail || 'Unknown error'));
            }
            const newLocation = await response.json();
            console.log('Added new location:', newLocation);
            setLocations(prevLocations => [...prevLocations, newLocation]);
            setEditId(newLocation.id);
            handleCloseAddModal();
        } catch (error) {
            console.error('Failed to add new location:', error);
            alert(error.message);  // 显示更具体的错误信息
        }
    };

    

    function Back_to_clock_back(){
        window.location.href = '/manager/clock_back'
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        {<KahHeaderManager/>}
  
        {/* MAIN CONTENT */}
        <div style={{ flex: 1 }}>
        {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

        {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
            <h2>打卡後台</h2>
            <p>共生製作-打卡地點後台</p>
            
            <table className="table">
            <thead>
                <tr>
                <th scope="col">打卡地點</th>
                <th scope="col">類型</th>
                <th scope="col">地址</th>
                <th scope="col">地點座標(緯度)</th>
                <th scope="col">地點座標(經度)</th>
                <th scope="col">操作</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                {locations.length > 0 ? (
                    locations.map((location, index) => (
                        <tr key={index}>
                            <th scope="row">{location.location}</th>
                            <td>{location.location_type}</td>
                            <td>{location.location_address}</td>
                            <td>{location.lat}</td>
                            <td>{location.lon}</td>
                            <td><Button variant="outline-dark" size="sm" onClick={() => handleEdit(location)}>編輯</Button></td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="6" className="text-center">沒有可顯示的資料</td>
                    </tr>
                )}
            </tbody>
            </table>

            <div className="text-center me-5 mt-3">
                <Button className="me-4 mt-3" variant="success" size="sm" onClick={handleAddNew}>新增</Button>
                <Button className="ms-4 mt-3" variant="danger" size="sm" onClick={Back_to_clock_back}>返回</Button>
            </div>
        
        

            <Modal show={showAddModal} onHide={handleCloseAddModal} centered 
                style={{
                    textAlign: 'left',
                    marginLeft: isSidebarOpen && !isMobile ? '135px' : 'auto',  // 當側邊欄開啟且非手機模式時，偏移 Modal
                    marginRight: 'auto',  // 自動調整右側間距以保持居中
                    maxWidth: isMobile ? '100%' : '100%'  // 桌面模式最大寬度為 500px
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>新增打卡地點</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formLocation">
                            <Form.Label>打卡地點</Form.Label>
                            <Form.Control type="text" placeholder="請輸入地點" name="location" value={newLocationData.location} onChange={handleNewLocationChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocationType">
                            <Form.Label>類型</Form.Label>
                            <Form.Select name="location_type" value={newLocationData.location_type} onChange={handleNewLocationChange}>
                                <option value="">請選擇類型</option>
                                <option value="辦公室">辦公室</option>
                                <option value="案場">案場</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocationAddress">
                            <Form.Label>地址</Form.Label>
                            <Form.Control type="text" placeholder="請輸入地址" name="location_address" value={newLocationData.location_address} onChange={handleNewLocationChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLat">
                            <Form.Label>地點座標(緯度)</Form.Label>
                            <Form.Control type="text" placeholder="請輸入緯度" name="lat" value={newLocationData.lat} onChange={handleNewLocationChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLon">
                            <Form.Label>地點座標(經度)</Form.Label>
                            <Form.Control type="text" placeholder="請輸入經度" name="lon" value={newLocationData.lon} onChange={handleNewLocationChange} />
                        </Form.Group>
                    </Form>
            </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>關閉</Button>
                    <Button variant="primary" onClick={handleAddNewLocation}>新增</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>編輯打卡地點</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formLocation">
                            <Form.Label>打卡地點</Form.Label>
                            <Form.Control type="text" placeholder="Enter location" name="location" value={editData.location || ''} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocationType">
                            <Form.Label>類型</Form.Label>
                            <Form.Control type="text" placeholder="Enter type" name="location_type" value={editData.location_type || ''} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocationAddress">
                            <Form.Label>地址</Form.Label>
                            <Form.Control type="text" placeholder="Enter address" name="location_address" value={editData.location_address || ''} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLat">
                            <Form.Label>地點座標(緯度)</Form.Label>
                            <Form.Control type="text" placeholder="Enter latitude" name="lat" value={editData.lat || ''} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLon">
                            <Form.Label>地點座標(經度)</Form.Label>
                            <Form.Control type="text" placeholder="Enter longitude" name="lon" value={editData.lon || ''} onChange={handleChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDelete(editData.id)}>
                        刪除
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        關閉
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        保存
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </div>
    {/* Footer */}
    <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />    
    </div>
  )
}

export default ClockBackLocation
