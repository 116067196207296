import React from 'react'
import { Stack , Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import { useNavigate } from 'react-router-dom';
import ModalImage from "react-modal-image";

import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'


function SalarlyLaw(){
    let navigate = useNavigate();

    function handleClick() {
        navigate('/salarly');
    }

    return(
    <div>
        <KahHeader title = "勞基法"/>
        <div className="row justify-content-center"> {/* 添加 row 和 justify-content-center 類別來水平居中對齊 */}
          <div className="col-md-5"> {/* 控制列的寬度 */}
            <h4 className='text-center'>勞基法薪資</h4>
            <div className="text-center mb-5">
                <img src="/Labor_standards_law.jpg" alt="勞基法" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
            <div className="d-flex justify-content-start ms-4"> {/* 使用 Flexbox 進行對齊 */}
                <Button className='btn btn-success' onClick={handleClick}>上一頁 <i className="fa fa-arrow-left"></i></Button> {/* 使用 HTML 實體表示箭頭 */}
            </div>
          </div>
        </div>
        <KahFooter/>
    </div>
    )
}

export default SalarlyLaw