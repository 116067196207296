import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
function useUserData(site) {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchUserDataFromCookies = () => {
            const userId = Cookies.get("user_id");
            const userName = Cookies.get("user_name");

            if (userId && userName) {
                try {
                    setUserData({
                        userId,
                        userName,
                    });
                } catch (error) {
                    console.error("Failed to parse user_permission:", error);
                    navigate(`/${site}`, { replace: true });
                }
            } else {
                console.warn("Some cookies are missing");
                navigate(`/${site}`, { replace: true });
            }
        };

        fetchUserDataFromCookies();
    }, []); // 僅在初始渲染時運行

    return userData; // 返回 user_id 和 user_name
}

export default useUserData;
