//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
//MaterialUI : https://mui.com/material-ui/getting-started/
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; //MaterialUI icon : https://mui.com/material-ui/material-icons/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeaderLogo from '../components/KahHeaderLogo'
import KahFooter from '../components/KahFooter'
import MonthSwitch from '../components/MonthSwitch'

function Absence_back() {
  const [absence, setAbsence] = useState([]);  // 狀態保存請假紀錄
  const [currentMonth, setCurrentMonth] = useState('');  // 狀態保存選擇的月份
  const [editingIndex, setEditingIndex] = useState(null); // 用來追踪正在編輯的員工索引
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
  const [isMobile, setIsMobile] = useState(false);  // 手機與電腦
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // 根據視窗大小監控是否為手機視圖
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
        setIsMobile(true);
        setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
      } else {
        setIsMobile(false);
        setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 抓取"後端"資料
  useEffect(() => {
    setLoading(true);
    axios.post('https://kah.dianalab.net/api/get-absence')
        .then(response => {
            console.log('Data fetched: ', response.data);
            setAbsence(response.data);
        })
        .catch(error => {
            console.error('Error fetching data: ', error);
            setError(error);
        });
  }, []);

  function handleEdit(index) {
    setEditingIndex(index); // 設置當前編輯的行
  }
  const handleMonthChange = (month) => {
    // 僅當月份更改時，發送請求
    if (month !== currentMonth) {
      setCurrentMonth(month);
      console.log('Month updated to:', month);
    }
  };

  function handleAbsenceForm() {
    window.location.href = '/absence_form';
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* SIDE BAR */}
      {<KahHeaderManager/>}

      {/* MAIN CONTENT */}
      <div style={{ flex: 1 }}>
        {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

        {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                <h2>請假後台</h2>
                <p>共生製作-請假後台</p>
                <div className='d-flex align-items-center'>
                  <MonthSwitch onMonthChange={handleMonthChange} />  {/* 傳入 handleMonthChange */}
                  <h5 className='mt-2'>請假紀錄</h5>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">員工姓名</th>
                      <th scope="col">假別累計</th>
                      <th scope="col">假別剩餘</th>
                      
                      <th scope="col">員工請假卡</th>
                      <th scope="col">假別編輯</th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    <tr>
                      <th scope="row" >員工1</th>
                      <td>病假-1日；特休-2日</td>
                      <td>特休-8日；陽光假(天)-5日</td>
                      
                      <td><a href="absence_back/card"><AssignmentOutlinedIcon></AssignmentOutlinedIcon></a></td>
                      <td><Link to="../manager/absence_back/edit" className="btn btn-outline-dark btn-sm">編輯</Link></td>
                    </tr>
                    <tr>
                      <th scope="row">員工2</th>
                      <td>病假-8日；公假-8日</td>
                      <td>特休-8日</td>
                      
                      <td><a href="absence_back/card"><AssignmentOutlinedIcon></AssignmentOutlinedIcon></a></td>
                      <td><Link to="../manager/absence_back/edit" className="btn btn-outline-dark btn-sm">編輯</Link></td>
                    </tr>
                  </tbody>
                </table>
              <div className="text-center d-flex flex-column ms-1">
                <Button className="mt-1" style={{ width: '200px' }} variant="outline-primary" size="sm" onClick={handleAbsenceForm}>
                    請假表單
                </Button>
                <Button className="mt-3" style={{ width: '200px' }} variant="outline-success" size="sm">
                    假期編輯(員工)
                </Button> 
                
                {/* <Button className="ms-3 mt-3" style={{ width: '200px' }} variant="outline-success" size="sm">
                    請假紀錄
                </Button>
                <Button className="ms-3 mt-3" style={{ width: '200px' }} variant="outline-dark" size="sm">
                    請假統計(年)
                </Button> */}
            </div>
            <div className='text-center mt-3'>
                  <Link to="../manager/absence_back/check" className="btn btn-danger btn-sm">請假訊息</Link>
            </div>
        </div>
        </div>
        {/* Footer */}
      <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Absence_back
