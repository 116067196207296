const LinebotLoginConfig = {
    kah: {
        CHANNEL_ID: "2006565157",
        CHANNEL_SECRET: "766709607488d85a2a9e06d2e5656d5d",
        DOMAIN: "https://kah.dianalab.net",
    },
    test: {
        CHANNEL_ID: "2005573301",
        CHANNEL_SECRET: "6369cd5c7281c0b24ca3de9b602ad920",
        DOMAIN: "https://kah.renjieli.cc",
    },
  };

export default LinebotLoginConfig;