// React
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

// Leaflet - 地圖
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
// Geolocation-取得使用者座標(https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition)

//KAH components
import KahHeaderLogo from './components/KahHeaderLogo-phone'
import KahFooter from './components/KahFooter'
import MUI_switch from './components/MUISwitch'
// Switches(https://react-bootstrap.netlify.app/docs/forms/checks-radios#switches)

//KAH hooks
import useLineAuth from './hooks/useLineAuth';
import useUserData from "./hooks/useUserData";
import useCheckPermission from "./hooks/useCheckPermission";

//KAH config
import LinebotType from "./config/line_bot_type"; // LINE Bot 類型設定

// @Component-切換上下班
function SwitchClock({ clockState, setClockState, setBusinessState, setUserLocation }) {
  return (
    <div className="d-flex justify-content-center">
      <nav><div className="nav nav-pills nav-fill mt-3 mb-3" role="tablist">
        <button
          className={`nav-link ${clockState === 'on' ? 'active' : ''} mx-2`}
          id="nav-clock-in"
          type="button"
          role="tab"
          aria-selected={clockState === 'on'}
          onClick={() => { 
            setClockState('on'); 
            setBusinessState(false);
            setUserLocation(null);  
          }}
        >上班
        </button>
        <button
          className={`nav-link ${clockState === 'off' ? 'active' : ''} mx-2`}
          id="nav-clock-out"
          type="button"
          role="tab"
          aria-selected={clockState === 'off'}
          onClick={() => { 
            setClockState('off'); 
            setBusinessState(false);
            setUserLocation(null);
          }}
        >下班
        </button>
      </div></nav>
    </div>
  );
}

// @Component-上下班打卡
function PunchClock({ clockState, businessState, setBusinessState, userId, userName, userLocation,setUserLocation }) {
  const [caseNames, setCaseNames] = useState([]);
  const [formData, setFormData] = useState({
    userid: '', //使用者id
    name: '', // 打卡人
    caseNumber: '', // 案場地點
    businessType: '', //去 回 去回
    date: '', // 公差日期
  });

  // 地圖尺寸
  const customIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + '/marker.png',
    iconSize: [48, 48], // 圖標的寬度和高度
    iconAnchor: [24, 48], // 圖標的底部中間點
    popupAnchor: [0, -48] // 調整 Popup 的位置
  });

  // 抓取"後端"資料
  useEffect(() => {
    // 向後端請求專案名稱（作為案場地點）
    axios.get('https://kah.dianalab.net/api/get-project-cases')
        .then(response => {
            setCaseNames(response.data);
        })
        .catch(error => {
            console.error('取得案場地點時發生錯誤:', error);
        });
  }, []);

  
  // 處理formData資料輸入
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // 切換公差狀態
  const handleBusinessState = () => {
    setBusinessState(!businessState); 
  };

  // @Get-使用者目前座標
  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError, {
        enableHightAccuracy: true, // 提高精確度
        timeout: 3000, // 最長等待時間（3秒）
        maximumAge: 0 // 接受的最大緩存時間，0 表示不接受緩存，每次都重新獲取
      });
    } else {
      alert("此瀏覽器不支援地理位置功能。");
    }
  };

  // @post-公差資訊
  const sendBusinessToServer = async (formData) => {
    // console.log('Business formData:', formData); //fixed
    const url = `https://kah.dianalab.net/api/clock/business`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      console.log('Business response:', data);

    } catch (error) {
      console.error('Error sending business info:', error);
    }
  };

  // @Process-使用者位置資訊
  const showPosition = (position) => {
    const currentDate = new Date();
    const clockDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`; //2024-05-26
    const clockTime = `${clockDate} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`; //2024-05-26 08:30:56
    const clockInfo = {
      userid: userId, //使用者id
      username: userName, //打卡人
      clockState: clockState, //打卡狀態(on:上班;off:下班;business:公差)
      date: clockDate, //打卡日期
      latitude: position.coords.latitude, // 緯度
      longitude: position.coords.longitude, // 經度
      clocktime: clockTime  //打卡詳細時間 on_time、off_time
    };
    setUserLocation(clockInfo);
    const businessInfo={
      userid: userId, //使用者id
      name: userName, // 打卡人
      caseNumber: formData.caseNumber, // 案場地點
      businessType: formData.businessType, //去 回 去回
      date: clockDate, // 公差日期
    }
    setFormData(prevData => ({
      ...prevData,
      ...businessInfo
    }));
    

    // @POST-clockInfo-打卡資訊
    // console.log('clockInfo:', clockInfo);
    const sendLocationToServer = async (clockInfo) => {
      const url = `https://kah.dianalab.net/api/clock`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(clockInfo)
      });
      const data = await response.json();
      console.log('data : ', data);

      switch (data.message) {
        case '上班打卡成功':
          alert(`上班 打卡成功 \n地點:  ${data.location} \n日期:  ${data.date} \n時間:  ${data.on_time} \n打卡人:  ${data.user}`);
          break;
        case '上班打卡成功(早到打卡)':
          alert(`上班 打卡成功 \n地點:  ${data.location} \n日期:  ${data.date} \n時間:  ${data.on_time} \n打卡人:  ${data.user} \n (提醒: 早到打卡)`);
          break;
        case '上班打卡成功(遲到)':
          alert(`上班 打卡成功 \n地點:  ${data.location} \n日期:  ${data.date} \n時間:  ${data.on_time} \n打卡人:  ${data.user} \n (提醒: 遲到 ${data.latetime} 分鐘)`);
          break;
        case '下班打卡成功(上班漏打卡)':
          alert(`下班 打卡成功 \n地點:  ${data.location} \n日期:  ${data.date} \n時間:  ${data.off_time}\n打卡人:  ${data.user} \n (提醒: 上班漏打卡)`);
          break;
        case '下班打卡成功(未滿8小時)':
          alert(`下班 打卡成功(未滿8小時) \n地點:  ${data.location} \n日期:  ${data.date} \n時間:  ${data.off_time} \n打卡人:  ${data.user}\n (提醒: 上班時數差 ${data.lacktime} 分鐘滿8小時)`);
          break;
        case '下班打卡成功':
          alert(`下班 打卡成功 \n地點:  ${data.location} \n日期:  ${data.date} \n時間:  ${data.off_time} \n打卡人:  ${data.user}`);
          break;
        case '重複打卡':
          alert(`打卡失敗 : 重複打卡`);
          break;
        case '早到超過120分鐘':
          alert(`打卡失敗 : 早到超過120分鐘`);
          break;
        case '地點錯誤打卡失敗':
          alert(`打卡失敗 : 地點錯誤打卡失敗`);
          break;
        case '誤打下班卡':
          alert(`打卡失敗 : 誤打下班卡`);
          break;
      }
    };
    sendLocationToServer(clockInfo);
    if (businessState) {
      sendBusinessToServer(businessInfo);
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("拒絕了請求座標定位，請允許瀏覽器獲取您的位置");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("位置訊息不可用，請檢查您的網路連接或者重新載入頁面");
        break;
      case error.TIMEOUT:
        alert("請求座標超時，請檢查您的網路連接或者重新載入頁面");
        break;
      default:
        alert("未知錯誤");
        break;
    }
  };

  
  return (
    <div className="d-flex flex-column align-items-center text-center">

      {/* Button-上班打卡 */}
      {clockState === 'on' ? (
        <Button onClick={getGeolocation} variant="outline-success" size="lg" className="mt-3 mb-4">
          上班打卡
        </Button>
      ) : null}

      {/* Button-下班(含公差) */}
      {clockState === 'off' ? (
        <>
          {/* 公差切換 */}
          <MUI_switch clockState={clockState} onSwitchChange={handleBusinessState} />

          {/* 下班打卡按鈕 */}
          {businessState ? (
            <>
              <Form.Group controlId="formBasicName" className="mt-1 mb-4">
                <Form.Select 
                      value={formData.caseNumber} 
                      onChange={handleInputChange}
                      name="caseNumber"
                  >
                      <option value="" disabled>選擇案場地點</option>
                      {caseNames.map((name, index) => (
                          <option key={index} value={name}>{name}</option>
                      ))}
                </Form.Select>

                <div key={`inline-radio`} className="mt-4">
                    <Form.Check
                      inline
                      label="去"
                      value="去"
                      name="businessType"
                      type='radio'
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      inline
                      label="回"
                      value="回"
                      name="businessType"
                      type='radio'
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      inline
                      label="去回"
                      value="去回"
                      name="businessType"
                      type='radio'
                      onChange={handleInputChange}
                    />
                  </div>
              </Form.Group>


              <Button onClick={getGeolocation} variant="outline-danger" size="lg" className="mt-1 mb-4">
                下班打卡
              </Button>
            </>

          ) : (
            <Button onClick={getGeolocation} variant="outline-danger" size="lg" className="mt-1 mb-4">
              下班打卡
            </Button>
          )}
        </>
      ) : null}
      
      {/* Map-打卡地點 */}
      {userLocation && (
        <MapContainer
          key={`${userLocation.latitude}-${userLocation.longitude}`} // 使用 location 更新作為 key
          center={[userLocation.latitude, userLocation.longitude]}
          zoom={15}
          style={{ height: '300px', width: '100%' }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[userLocation.latitude, userLocation.longitude]} icon={customIcon}>
            <Popup>您在這裡打卡。時間：{userLocation.clocktime}</Popup>
          </Marker>
        </MapContainer>
      )}

    </div>
  );
}


// 打卡
function Clock() {
  const { user, loading} = useLineAuth(LinebotType, "clock"); //botType = "test" or "kah", site = "clock"
  const userData = useUserData("clock"); // 使用者資料
  const [clockState, setClockState] = useState('on'); // 'on' 表示上班，'off' 表示下班
  const [businessState, setBusinessState] = useState(false); // 用來儲存公差狀態
  const [userLocation, setUserLocation] = useState(null);
  const navigate = useNavigate(); // 頁面導覽

  // 檢查使用者權限(SYSAdmin、Boss、HR、Staff、Customer、Other)
  useEffect(() => {
    // console.log("loading:", loading)
    // console.log("permission:", user?.permission)
    if (!loading) { 
      // console.log("User:", user);
      const allowedRoles = ["SYSAdmin", "Boss", "HR", "Staff"]; // 允許進入的權限
      const line_permission = user.permission.user_permission.line_permission
      
      if (!allowedRoles.includes(line_permission)) {
        console.error("Insufficient permission. Redirecting to homepage...");
        navigate("/"); // 非允許權限者，重新導向首頁
      }
      else{
        console.log("permission correct");
        // console.log("permission:",line_permission);
      }
    }
  }, [loading, navigate]);
  
  // const allowedRoles_cookie = {
  //   features: [],
  //   line_permission:["SYSAdmin", "Boss", "HR", "Staff"]
  // };
  // useCheckPermission(userData , allowedRoles_cookie, "/");

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div style={{ flex: 1 }}>
        <KahHeaderLogo isSidebarOpen={false} isMobile={true} />
        <SwitchClock
          clockState={clockState}
          setClockState={setClockState}
          setBusinessState={setBusinessState}
          setUserLocation={setUserLocation}
        />
        <PunchClock
          clockState={clockState}
          businessState={businessState}
          setBusinessState={setBusinessState}
          userId={user.id}
          userName={user.name}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
        />
      </div>
      <KahFooter isSidebarOpen={false} isMobile={true} />
    </div>

  );
}

export default Clock;