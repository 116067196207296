// 行事曆
import { useEffect, useRef } from 'react';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

const defaultLocale = {
    days: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    daysShort: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
    daysMin: ['日', '一', '二', '三', '四', '五', '六'],
    months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    monthsShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    today: '今天',
    clear: '清除',
    dateFormat: 'yyyy/MM/dd',
    timeFormat: 'hh:mm aa',
    firstDay: 0,
};

const useAirDatepicker = (options) => {
    const datepickerRef = useRef(null);
    const datepickerInstance = useRef(null);

    useEffect(() => {
        if (datepickerRef.current && !datepickerInstance.current) {
            datepickerInstance.current = new AirDatepicker(datepickerRef.current, {
                autoClose: true,
                locale: defaultLocale,
                ...options, // 外部傳入的配置會覆蓋預設值
            });
        }

        return () => {
            if (datepickerInstance.current) {
                datepickerInstance.current.destroy();
                datepickerInstance.current = null;
            }
        };
    }, [options]);

    return datepickerRef;
};

export default useAirDatepicker;
