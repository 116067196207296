import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';


import KahHeaderManager from '../components/KahHeaderManager';
import KahFooter from '../components/KahFooter';
import KahHeaderLogo from '../components/KahHeaderLogo';

function Project_back() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
  const [isMobile, setIsMobile] = useState(false);  // 手機與電腦
  
  // 根據視窗大小監控是否為手機視圖
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
        setIsMobile(true);
        setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
      } else {
        setIsMobile(false);
        setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function handleAddPermission() {
    // console.log("add permission");
    window.location.href = '/manager/project/add'; // 直接導向到指定URL
  }

  return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* SIDE BAR */}
      {<KahHeaderManager/>}

      {/* MAIN CONTENT */}
      <div style={{ flex: 1 }}>
        {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

        {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
          <h2>專案管理</h2>
          <p>共生製作-專案管理後台</p>
          <table class="table" >
            <thead>
                <tr>
                <th scope="col">專案名稱</th>
                <th scope="col">專案開始</th>
                <th scope="col">專案結束</th>
                <th scope="col">編輯</th>
                </tr>
            </thead>
          </table>
          <div className="text-center">
              <Button className="me-4 mt-3" variant="success" size="sm" onClick = {handleAddPermission}>新增</Button>
              {/* <Button className="ms-4 mt-3" variant="danger" size="sm">編輯</Button> */}
          </div>
        </div>
      </div>
      {/* Footer */}
      <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  );
}

export default Project_back;
