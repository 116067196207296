import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from 'react-bootstrap';

import KahHeader from './components/KahHeaderText'
import KahFooter from './components/KahFooter'

function ScheduleAdd() {
    return (
        <div>
            <KahHeader title = "進度"/>
            <div className="container">
                <h4 className='text-center mb-4'>共生室內設計專案</h4>
                <Form>
                    <Form.Group className="mb-3" controlId="projectName">
                        <Form.Label>專案名稱</Form.Label>
                        <Form.Control type="text" placeholder="輸入專案名稱" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="startDate">
                        <Form.Label>日期</Form.Label>
                        <Form.Control type="date"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="endDate">
                        <Form.Label>照片</Form.Label>
                        <Form.Control type="file" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>標題</Form.Label>
                        <Form.Control type="text" placeholder="輸入工項標題"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>描述</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                    <div className="d-flex justify-content-center mt-4">
                        <Button variant="primary" type="submit">上傳</Button>
                    </div>
                </Form>
            </div>
            <KahFooter/>
        </div>
    );
}

export default ScheduleAdd;
