import React, { useState, useEffect } from 'react';
import { FaBars, FaCog} from 'react-icons/fa'; // 引入設定圖案和箭頭圖案

function KahHeaderManager() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 控制側邊欄展開狀態
  const [isMobile, setIsMobile] = useState(false);  // 手機版視圖狀態
  
  // 根據視窗大小監控是否為手機視圖
  useEffect(() => {
    const width = document.documentElement.clientWidth;
    console.log(width);
    // window.innerWidth
    const handleResize = () => {
      if (width <= 768) {  // 設置寬度閾值（768px）
        setIsMobile(true);
        setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
      } else {
        setIsMobile(false);
        setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 切換側邊欄的顯示狀態
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // CSS styles
  const sidebarStyle = {
    width: isSidebarOpen ? '250px' : '0', // 側邊欄的寬度
    height: '100vh',
    backgroundColor: '#f8f9fa',
    position: 'fixed',
    left: 0,
    top: 0,
    transition: 'width 0.3s ease',
    overflow: 'auto',
    zIndex: 2500,
    textAlign: 'center',
  };
  const iconStyle = {
    fontSize: '30px',
    cursor: 'pointer',
    color: '#333',
  };
  const navItemStyle = {
    padding: '20px 20px', // 側邊欄選項
    cursor: 'pointer',
    color: '#333',
    textDecoration: 'none',
    display: isSidebarOpen ? 'block' : 'none',
    fontSize: '20px',
  };
  const navItemHoverStyle = {
    backgroundColor: '#AF8E5E', // 側邊欄選項(被選擇時)
    color: '#fff',
  };

  return (
    <div>
      {/* 左上角的圖示 (手機視圖) */}
      {isMobile && (
        <div style={{ position: 'fixed', top: 10, left: 10, zIndex: 3000 }}>
          <FaBars style={{ fontSize: '24px', cursor: 'pointer' }} onClick={toggleSidebar} />
        </div>
      )}
      {/* 側邊欄 */}
      <div className="sidebar bg-light" style={sidebarStyle}>
      <div className="py-4" style={{ flex: 1 }}>
        {isSidebarOpen && (
          <>
            <h5 className="text-center mt-2 mb-4"><FaCog style={iconStyle} /> 後台管理</h5>
            <ul className="nav flex-column">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="/manager/"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  首頁
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="/manager/permission"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  權限後台
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="/manager/employee"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  員工管理
                </a>
              </li>
              


              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/manager/clock_back"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  打卡後台
                </a>
              </li>

              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="/manager/absence_back"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  請假後台
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/manager/repayment_back"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  請款後台
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/manager/salary_back"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  薪資後台
                </a>
              </li>*/}

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/manager/schedule_back"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  進度後台
                </a>
              </li>
{/* 
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/manager/repair_back"
                  style={navItemStyle}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
                    e.target.style.color = navItemHoverStyle.color;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = navItemStyle.color;
                  }}
                >
                  報修後台
                </a>
              </li> */}

            </ul>
          </>
        )}
      </div>

      {/* 遮罩 */}
      {isMobile && isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          style={{
            position: 'fixed',
            top: 0,
            left: isSidebarOpen ? '250px' : '0', // 從螢幕最左邊開始
            transition: 'width 0.4s ease',
            width: '100%', // 覆蓋整個螢幕寬度
            height: '100%', // 覆蓋整個螢幕高度
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // 透明遮罩
            zIndex: 50, // 確保遮罩的層級低於側邊欄
          }}
        ></div>
      )}

      
    </div>
    </div>
    
  );
}

export default KahHeaderManager;


//<div style={{ marginTop: 'auto', paddingBottom: '20px' }}> {/* 將箭頭移至最底部 */}
//{isSidebarOpen ? (
//  <FaArrowLeft style={iconStyle} onClick={toggleSidebar} />
//) : (
//  <FaArrowRight style={iconStyle} onClick={toggleSidebar} />
//)}</div>


{/* <li className="nav-item">
  <a
    className="nav-link active"
    href="http://kah.renjieli.cc/manager/project_back"
    style={navItemStyle}
    onMouseEnter={(e) => {
      e.target.style.backgroundColor = navItemHoverStyle.backgroundColor;
      e.target.style.color = navItemHoverStyle.color;
    }}
    onMouseLeave={(e) => {
      e.target.style.backgroundColor = '';
      e.target.style.color = navItemStyle.color;
    }}
  >
    專案管理
  </a>
</li> */}