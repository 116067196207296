//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import Cookies from "js-cookie";
//MaterialUI : https://mui.com/material-ui/getting-started/
//MaterialUI icon : https://mui.com/material-ui/material-icons/
import SaveIcon from '@mui/icons-material/Save';
//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeaderLogo from '../components/KahHeaderLogo'
import KahFooter from '../components/KahFooter'
import { FormControl } from 'react-bootstrap';
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";

function Schedule_back() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768); // 響應式側邊欄
    const userData = useUserData(); // Cookies 取得userId、userName資料
    const [schedule, setSchedule] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null); // 用來追踪正在編輯的員工

    const allowedRoles = {
        features: [],
        line_permission:["Boss", "SYSAdmin", "HR"]
    };
    useCheckPermission(userData , allowedRoles, "/manager/");
    
    // 抓取"後端"資料
    useEffect(() => {
        setLoading(true);
        axios.post('https://kah.dianalab.net/api/getSchedule')
            .then(response => {
                // console.log("getSchedule:", response.data);
                setSchedule(response.data);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);  // 請求完成後設置加載結束
            });
    }, []);

    function handleSearch(){
        // window.location.href = '/schedule_back'
        window.location.href = '/manager/'
        
    }

    function handleAdd() {
        const user_id = Cookies.get("user_id");
        const user_name = Cookies.get("user_name");
        const permission = Cookies.get("user_permission");
        Cookies.set("user_id", user_id, { secure: true, path: "/manager" });
        Cookies.set("user_name", user_name, { secure: true, path: "/manager" });
        Cookies.set("user_permission", permission, { secure: true, path: "/manager" });
        window.location.href = '/manager/schedule_back/add'; // 直接導向到指定URL
    }

    function handleEdit(index) {
        setEditingIndex(index); // 設置當前編輯的行
    }
    const handleCancelEdit = () => {
        setEditingIndex(null);
    };
    function handleSave(index) {
        // 取出員工的ID
        const scheduleId = schedule[index]?.id;

        if (!scheduleId) {
            console.error('Error: scheduleId is undefined or null');
            return;  // 如果没有 id，停止进一步操作
        }
    
        const updatedSchedule = {
            ...schedule[index],
            project_end: schedule[index].project_end === '' ? null : schedule[index].project_end,
        };

        console.log('updatedSchedule:', updatedSchedule);
        axios.put(`https://kah.dianalab.net/api/update-employee`, updatedSchedule)
            .then(response => {
                console.log('Schedule updated successfully.');
                setEditingIndex(null); // 完成編輯後重置編輯狀態
            })
            .catch(error => {
                console.error('Error updating schedule:', error);
            });
    }

    function handleDelete(projectName) {
        // 發送刪除請求到後端，將 Project.is_delete 設為 1
        axios.post(`https://kah.dianalab.net/api/delete-schedule`, { name: projectName })
            .then(response => {
                console.log('Schedule marked as deleted.');
                setSchedule(schedule.filter(sch => sch.name !== projectName)); // 從前端過濾掉刪除的專案
            })
            .catch(error => {
                console.error('Error deleting schedule:', error);
            });
    }
    

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        {<KahHeaderManager/>}
  
        {/* MAIN CONTENT */}
        <div style={{ flex: 1 }}>
        {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
            <h2>進度後台</h2>
            <p>共生製作-進度後台</p>
            
            <table class="table" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
            <thead>
                <tr>
                <th scope="col" style={{ width: '10%' }}>專案名稱</th>
                <th scope="col" style={{ width: '15%' }}>專案開始</th>
                <th scope="col" style={{ width: '15%' }}>專案結束</th>
                {/* <th scope="col">工程期程表</th> */}
                <th scope="col" style={{ width: '10%' }}>進度查詢(Line)</th>
                <th scope="col" style={{ width: '10%' }}>編輯</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                {schedule.map((sch, index) => (
                    <tr key={index}>
                    {editingIndex === index ? (
                    <>
                    {/* 專案名稱 */}
                    <td><FormControl type='text' size='sm' value={sch.name} onChange={(e) =>{
                        const updatedSchedule = [...schedule];
                        updatedSchedule[index].name = e.target.value;
                        setSchedule(updatedSchedule);
                    }}/></td>
                    {/* 專案開始 */}
                    <td><FormControl type='date' size='sm' value={sch.project_start} onChange={(e) =>{
                        const updatedSchedule = [...schedule];
                        updatedSchedule[index].project_start = e.target.value;
                        setSchedule(updatedSchedule);
                    }}/></td>
                    {/* 專案結束 */}
                    <td><FormControl type='date' size='sm' value={sch.project_end} onChange={(e) =>{
                        const updatedSchedule = [...schedule];
                        updatedSchedule[index].project_end = e.target.value;
                        setSchedule(updatedSchedule);
                    }}/></td>
                    {/* 工程期程表 */}
                    {/* <td><FormControl type='text' size='sm' value={sch.project_url} onChange={(e) =>{
                        const updatedSchedule = [...schedule];
                        updatedSchedule[index].project_url = e.target.value;
                        setSchedule(updatedSchedule);
                    }}/></td> */}
                    {/* 進度查詢(Line) */}
                    <td></td>
                    <td style={{ verticalAlign: 'middle'}}>
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="primary" size="sm" onClick={() => handleSave(index)}>
                                <SaveIcon /> {/* 插入 SaveIcon */}
                            </Button>
                            <FaTrash 
                                    style={{ color: 'gray', marginLeft: '20px', cursor: 'pointer' }}
                                    onClick={() => handleDelete(sch.name)}
                            /> {/* FaTrashIcon */}
                        </div>
                    </>
                    </td>
                    </>
                    ) : (
                    <>
                    {/* 專案名稱 */}
                    <td>{sch.name}</td>
                    {/* 專案開始 */}
                    <td>{sch.project_start}</td>
                    {/* 專案結束 */}
                    <td>{sch.project_end}</td>
                    {/* 工程期程表 */}
                    {/* <td><a href={sch.project_url}>Google Sheet</a></td> */}
                    {/* 進度查詢(Line) */}
                    <td style={{ verticalAlign: 'middle'}}>
                        <Button variant="outline-dark" size="sm" onClick={handleSearch}>查詢</Button>
                    </td>
                    {/* 進度編輯 */}
                    <td style={{ verticalAlign: 'middle'}}>
                        <Button variant="outline-dark" size="sm" onClick={() => handleEdit(index)}>編輯</Button>
                    </td>
                    </>
                    )}
                    </tr>
                ))}
            </tbody>
            </table>
            <div className="text-center">
                {editingIndex === null ? (
                    <Button className="me-4 mt-3" variant="success" size="sm" onClick={handleAdd}>
                        新增
                    </Button>
                ) : (
                    <Button className="me-4 mt-3" variant="danger" size="sm" onClick={handleCancelEdit}>
                        取消
                    </Button>
                )}
            </div>     
        </div>
                
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Schedule_back
