//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import axios from 'axios';

//MaterialUI : https://mui.com/material-ui/getting-started/
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; //MaterialUI icon : https://mui.com/material-ui/material-icons/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeaderLogo from '../components/KahHeaderLogo'
import KahFooter from '../components/KahFooter'
import MonthSwitch from '../components/MonthSwitch'

function Repayment_back() {
    const [currentMonth, setCurrentMonth] = useState('');  // 狀態保存選擇的月份
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
    const [isMobile, setIsMobile] = useState(false);  // 手機與電腦
    const [repayments, setRepayments] = useState([]);  // 請款紀錄

    // 根據視窗大小監控是否為手機視圖
    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
            setIsMobile(true);
            setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
        } else {
            setIsMobile(false);
            setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // 抓取"後端"資料
    useEffect(() => {
        // 當 currentMonth 改變時發送請求
        if (currentMonth) {
          axios.post('https://kah.dianalab.net/api/get-repayment', { month: currentMonth })
            .then(response => {
              setRepayments(response.data);
              console.log('Repament records:', response.data);
            })
            .catch(error => {
              console.error('Error fetching clock records:', error);
            });
        }
      }, [currentMonth]);

    function handleEditRepayment(){
        window.location.href = '/manager/repayment_back/search'
    }
    const handleMonthChange = (month) => {
        // 僅當月份更改時，發送請求
        if (month !== currentMonth) {
          setCurrentMonth(month);
          console.log('Month updated to:', month);
        }
      };

    function handleToRepaymentForm(){
        window.location.href = '/repayment_form'
    }

    function handleToRepaymentRecord(){
        window.location.href = '/repayment_search'
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        {<KahHeaderManager/>}
  
        {/* MAIN CONTENT */}
        <div style={{ flex: 1 }}>
          {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
  
          {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
            <h2>請款後台</h2>
            <p>共生製作-請款後台</p>
            <div className='d-flex align-items-center'>
                <MonthSwitch onMonthChange={handleMonthChange} />  {/* 傳入 handleMonthChange */}
                <h5 className='mt-2'>請款紀錄</h5>
            </div>
            <table class="table">
            <thead>
                <tr>
                <th scope="col">員工姓名</th>
                <th scope="col">代墊金額</th>
                <th scope="col">代墊紀錄表(Line)</th>
                <th scope="col">代墊紀錄表</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                {repayments.map((repayment, index) => (
                    <tr key={index}>
                        <th scope="row">{repayment.employee_name}</th>
                        <td>{isNaN(repayment.total_repayment) ? repayment.total_repaymenty : parseInt(repayment.total_repayment).toLocaleString()}</td>
                        <td><a href="../repayment"><AssignmentOutlinedIcon /></a></td>
                        <td><Button variant="outline-dark" size="sm" onClick={handleEditRepayment}>查詢</Button></td>
                    </tr>
                ))}
            </tbody>
            </table>
            <div className="text-center d-flex flex-column ms-1">
                <Button className="mt-1" style={{ width: '200px' }} variant="outline-primary" size="sm" onClick={handleToRepaymentForm}>
                    請款表單
                </Button>
                <Button className="mt-3" style={{ width: '200px' }} variant="outline-success" size="sm" onClick={handleToRepaymentRecord}>
                    請款紀錄
                </Button>
            </div>
        </div>  
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Repayment_back


{/* <a href="https://docs.google.com/forms/d/1cB2qS6TKUioW1hcN-t_e0fr0eo3R4S_ePewnFjGqM9c/edit" style={{ textDecoration: 'none', color: 'inherit' }}>請款表單-Google</a> */}