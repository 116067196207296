import React, { useState, useEffect, useRef } from 'react';

// Bootstrap - CSS 樣式文件
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

// Material-UI components
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

//Air Datepicker
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

//line-liff : https://developers.line.biz/en/docs/liff/overview/
import liff from '@line/liff'; 

// KAH components
import KahHeaderLogo from './components/KahHeaderLogo'

const LIFFID = '2005573301-oalj3m6r';

function Repair_form() {
    const [loading, setLoading] = useState(false); // loading state
    const [success, setSuccess] = useState(false); // success state
    const timerRef = React.useRef();
    const datepickerRef = useRef(null);
    const datepickerInstance = useRef(null); // 用於儲存日期選擇器實例
    
    //Repair form data
    const [formData, setFormData] = useState({
        reporter: '',            // 報修人
        phone: '',               // 手機
        repairItem: '',          // 報修項目
        additionalInfo: ''       // 其他說明
    });

    const zh_TW = {
        days: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        daysShort: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
        daysMin: ['日', '一', '二', '三', '四', '五', '六'],
        months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        monthsShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        today: '今天',
        clear: '清除',
        dateFormat: 'yyyy/MM/dd',
        timeFormat: 'hh:mm aa',
        firstDay: 0
    };

    // 初始化日期選擇器
    useEffect(() => {
        if (datepickerRef.current && !datepickerInstance.current) {
            datepickerInstance.current = new AirDatepicker(datepickerRef.current, {
                locale: zh_TW, // 設定語言
                dateFormat: 'yyyy/MM/dd', // 設定日期格式
                autoClose: true, // 選擇日期後自動關閉日曆
                onSelect({ formattedDate }) {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        buyDate: formattedDate
                    }));
                }
            });
        }
    }, []);

    // 初始化 LIFF 並獲取使用者資料
    useEffect(() => {
        liff.init({ liffId: LIFFID }) // 替換為你的 LIFF ID
            .then(() => {
                if (!liff.isLoggedIn()) {
                    liff.login(); // 如果未登入則要求登入
                } else {
                    liff.getProfile()
                        .then(profile => {
                            const displayName = profile.displayName; // 取得 LINE 名稱
                            console.log('使用者名稱：', displayName);
                            // 將名稱填入表單
                            setFormData(prevData => ({
                                ...prevData,
                                reporter: displayName
                            }));
                        })
                        .catch(err => {
                            console.error('抓取使用者資料失敗', err);
                        });
                }
            })
            .catch(err => {
                console.error('LIFF 初始化失敗', err);
            });
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        console.log('repair_form data:', formData);
        event.preventDefault();

        // 从 formData 中排除 additionalInfo
        const { additionalInfo, ...requiredFields } = formData;

        // 校验所有必须的字段是否为空
        if (Object.values(requiredFields).some(x => x === '')) {
            alert('所有欄位（除了其他說明）都必須填寫！');
            return;
        }
        
        setLoading(true);
        fetch('https://kah.dianalab.net/api/repair', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            setLoading(false);
            if (!response.ok) {
                throw new Error(`提交失敗，請稍後再試！(Status Code: ${response.status})`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
                setSuccess(false);
            }, 1500);  // Hide success message after 3 seconds
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);
            alert('提交失敗，請稍後再試！');
        });
    };

    // 載入狀態
    const handleClose = () => {
        setLoading(false);
        setSuccess(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Form className="mb-3" onSubmit={handleSubmit}>
                <KahHeaderLogo />
                <Form.Group className="mb-2" controlId="reporter">
                    <Form.Label>報修人</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="請輸入您的名字" 
                        value={formData.reporter} 
                        onChange={handleInputChange} 
                        name="reporter"
                        readOnly 
                   />
                </Form.Group>
                <Form.Group className="mb-2" controlId="phone">
            <Form.Label>手機</Form.Label>
            <Form.Control 
                type="text" 
                placeholder="請輸入您的手機號碼" 
                value={formData.phone} 
                onChange={handleInputChange} 
                name="phone"
            />
            </Form.Group>

            <Form.Group className="mb-2" controlId="repairItem">
                <Form.Label>報修項目</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="請輸入報修項目" 
                    value={formData.repairItem} 
                    onChange={handleInputChange} 
                    name="repairItem"
                />
            </Form.Group>

            <Form.Group className="mb-2" controlId="additionalInfo">
                <Form.Label>其他說明</Form.Label>
                <Form.Control 
                    as="textarea" 
                    placeholder="請輸入其他說明" 
                    value={formData.additionalInfo} 
                    onChange={handleInputChange} 
                    name="additionalInfo"
                    rows={3}  // 指定文本框的高度
                />
            </Form.Group>
            <div className='d-flex justify-content-center mt-3'>
                <Button variant="success" type="submit">
                    提交
                </Button>
            </div>
                
            </Form>

            {/* Material-UI Dialog with Progress Indicator */}
            <Dialog open={loading || success} onClose={handleClose}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        {loading && <CircularProgress size={68} sx={{ color: green[500] }} />}
                        {success && (
                            <>
                                <CheckIcon sx={{ color: green[500], fontSize: 60 }} />
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    成功提交
                                </Typography>
                            </>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
    }

export default Repair_form
