//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

//MaterialUI : https://mui.com/material-ui/getting-started/
//https://mui.com/x/react-data-grid/editing/#full-featured-crud
//https://mui.com/material-ui/getting-started/templates/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';


function Manager_back_add() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
    const [formData, setFormData] = useState({
        name: '',
        line_permission: '',
        back_permission: '',
      });
    const [agents, setAgents] = useState([]); // State to store agents
    const [showBackPermission, setShowBackPermission] = useState(false); // 控制後台權限是否顯示

    // 抓取"後端"資料
    useEffect(() => {
      axios.post('https://kah.dianalab.net/api/get-add-member-name')
          .then(response => {
              setAgents(response.data); // Store agents data
          })
          .catch(error => {
              console.error('Error fetching agents:', error);
          });
    }, []);
    
    const handleSubmit = (event) => {
      event.preventDefault();
      console.log("Form submitted with data:", formData);
      axios.post('https://kah.dianalab.net/api/create-member-permission', formData)
        .then(response => {
            console.log('Response:', response.data);
            window.location.href = '/manager/permission'; // 直接導向到指定URL
        })
        .catch(error => {
            console.error('There was an error creating the member permission!', error);
        });
    };

    const handleInputChange = (event) => {
      const { id, value } = event.target;
      setFormData({ ...formData, [id]: value });

      // 動態控制後台權限是否顯示
      if (id === 'line_permission') {
        if (value === 'Staff') {
            setShowBackPermission(true); // 當選擇內部時，顯示後台權限
        } else {
            setShowBackPermission(false); // 當選擇業主時，隱藏後台權限
        }
    }
    };  


    return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* SIDE BAR */}
      <KahHeaderManager />

      {/* MAIN CONTENT */}
      <div style={{ flex: 1, paddingBottom: '100px' }}> {/* flex: 1 保證內容撐滿剩餘空間 */}
        {/* Logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        {/* Content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
            <h2>人員權限管理</h2>
            <p>共生製作-人員權限管理後台</p>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label>Line名稱</Form.Label>
                  <Form.Select value={formData.name} onChange={handleInputChange}>
                      <option value="" disabled>選擇名稱</option>
                      {agents.map((agent, index) => (
                          <option key={index} value={agent.display_name}>
                              {agent.display_name}
                          </option>
                      ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="line_permission" className='mt-2'>
                  <Form.Label>Line權限</Form.Label>
                  <Form.Select value={formData.line_permission} onChange={handleInputChange}>
                    <option value="" disabled>選擇權限</option>
                    <option value="Staff">內部</option>
                    <option value="Customer">業主</option>
                  </Form.Select>
                </Form.Group>

                {/* 動態顯示後台權限選項 */}
                {showBackPermission && (
                  <Form.Group controlId="back_permission" className='mt-2'>
                  <Form.Label>後台權限</Form.Label>
                  <Form.Select value={formData.back_permission} onChange={handleInputChange}>
                      <option value="" disabled>選擇權限</option>
                      <option value="Staff">員工</option>
                      <option value="HR">人資</option>
                      <option value="Boss">老闆</option>
                  </Form.Select>
                  </Form.Group>
                  )}

                <Button variant="success" className="mt-3" type="submit">
                  提交
                </Button>

            </Form>
          </div>

        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
      </div>
    );
  };

export default Manager_back_add


{/* <Form.Group controlId="reason" className='mt-2'>
                <Form.Label>到職日</Form.Label>
                <Form.Control type="text" placeholder="請輸入員工薪水" value={formData.reason} onChange={handleInputChange} />
              </Form.Group> */}