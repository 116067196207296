//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import axios from 'axios';

//MaterialUI : https://mui.com/material-ui/getting-started/
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; //MaterialUI icon : https://mui.com/material-ui/material-icons/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'
import MonthSwitch from '../components/MonthSwitch'

function SalaryBack() {
    const [currentMonth, setCurrentMonth] = useState('');  // 狀態保存選擇的月份
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
    const [isMobile, setIsMobile] = useState(false);  // 手機與電腦
    const [isSalary, setIsSalary] = useState(false);  // 當月薪資紀錄是否被建立
    const [salaryRecords, setSalaryRecords] = useState([]);  // 薪資紀錄

    // 根據視窗大小監控是否為手機視圖
    useEffect(() => {
        const width = document.documentElement.clientWidth;
        const handleResize = () => {
        if (width <= 768) {  // 設置寬度閾值（768px）
            setIsMobile(true);
            setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
        } else {
            setIsMobile(false);
            setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // 抓取"後端"資料
    useEffect(() => {
        // 當 currentMonth 改變時發送請求
        if (currentMonth) {
          axios.post('https://kah.dianalab.net/api/get-salary-month', { month: currentMonth })
            .then(response => {
                setIsSalary(response.data.isSalary);
                setSalaryRecords(response.data.salary_records);
                console.log('Salary records:', response.data);
            })
            .catch(error => {
                console.error('Error fetching Salary records:', error);
            });
        }
      }, [currentMonth]);

    function handleEditSalary(){
        window.location.href = '/manager/salary_back/edit'
    }

    const handleMonthChange = (month) => {
        // 僅當月份更改時，發送請求
        if (month !== currentMonth) {
          setCurrentMonth(month);
          console.log('Month updated to:', month);
        }
      };

      const loadSalaryData = () => {
        // 当 currentMonth 改变时发送请求
        if (currentMonth) {
            axios.post('https://kah.dianalab.net/api/load-salary-month', { month: currentMonth })
                .then(response => {
                    setIsSalary(true);
                    setSalaryRecords(response.data);
                    console.log('Salary records:', response.data);
                })
                .catch(error => {
                    console.error('Error fetching Salary records:', error);
                });
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        {<KahHeaderManager />}
  
        {/* MAIN CONTENT */}
        <div style={{ flex: 1 }}>
            {/* logo */}
            <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

            {/* content */}
            <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                <h2>薪資後台</h2>
                <p>共生製作-薪資後台</p>
                <div>
                    <MonthSwitch onMonthChange={handleMonthChange} /> {/* 傳入 handleMonthChange */}
                    <h5 className='mt-2'>薪資紀錄</h5>
                </div>

                {/* Render Table or Load Salary Button */}
                
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">員工姓名</th>
                                <th scope="col">本薪</th>
                                <th scope="col">加班費</th>
                                <th scope="col">獎金</th>
                                <th scope="col">請假</th>
                                <th scope="col">薪水加總</th>
                                <th scope="col">薪資表(Line)</th>
                                <th scope="col">薪資表</th>
                            </tr>
                        </thead>
                        {isSalary ? (
                        <tbody className="table-group-divider">
                            {salaryRecords.map((record, index) => (
                                <tr key={index}>
                                    <th scope="row">{record.employee_name}</th>
                                    <td>{isNaN(record.basic_salary) ? record.basic_salary : parseInt(record.basic_salary).toLocaleString()}</td>
                                    <td>{isNaN(record.overtime_pay) ? record.overtime_pay : parseInt(record.overtime_pay).toLocaleString()}</td>
                                    <td>{isNaN(record.bonus) ? record.bonus : parseInt(record.bonus).toLocaleString()}</td>
                                    <td>{isNaN(record.leave_deduction) ? record.leave_deduction : parseInt(record.leave_deduction).toLocaleString()}</td>
                                    <td>{isNaN(record.total_salary) ? record.total_salary : parseInt(record.total_salary).toLocaleString()}</td>
                                    <td><a href="../salary"><AssignmentOutlinedIcon /></a></td>
                                    <td><Button variant="outline-dark" size="sm" onClick={handleEditSalary}>編輯</Button></td>
                                </tr>
                            ))}
                        </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="9" className="text-center">沒有可顯示的資料</td>
                                </tr>
                            </tbody>
                        )}
                    </table>

                    {isSalary ? (
                        <div className="text-center mt-5">
                            {/* <Button className="ms-3 " style={{ width: '200px' }} variant="outline-primary" size="sm">
                                本薪編輯(年度)
                            </Button> */}
                        </div>
                    ) : (
                        <div className="text-center mt-5">
                            <Button variant="outline-primary" size="sm" onClick={loadSalaryData}>
                                載入薪水
                            </Button>
                        </div>
                    )}

                
            </div>
        </div>

        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default SalaryBack
