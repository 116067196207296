import React from 'react'

const invoiceBoxStyle = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '23px',
};


function RepayKahHeader() {

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'start',marginLeft:'19px',alignItems: 'flex-end', height: '80px' }}>
        <img src="./KAH_Design_Icon_2.png" alt="KAH Design" style={{ maxWidth: '80%', maxHeight: '80%' }} />
      </div>
    </div>

  );
}

export default RepayKahHeader
