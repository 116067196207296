import React from 'react'

function KahHeaderText({title}) {

  return (
    <div className="text-center mt-4 mb-3">
      <h1>KAH Design {title}</h1>
    </div>
  );
}

export default KahHeaderText
