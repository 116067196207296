// Home.js
import React from 'react';
import Button from 'react-bootstrap/Button';
import KahHeaderLogo from './components/KahHeaderLogo'

function Home() {

  function handleLinkToWebsite() {
    window.location.href = 'https://kaharchitecture.com/main';
  }
  
  
  return (
    <div style={{ 
      display: 'flex', 
      boxSizing: 'border-box',
      minHeight: '100vh',
      flexDirection: 'column',
      justifyContent: 'center', // 垂直居中
      alignItems: 'center', // 水平居中
      textAlign: 'center',
       }}>
      {/* logo */}
      <KahHeaderLogo />
      
      {/* 黑白色系官網按鈕 */}
      <Button 
        className="mt-3"
        onClick={handleLinkToWebsite} 
        style={{
          backgroundColor: '#000',        // 黑色背景
          color: '#fff',                   // 白色文字
          border: 'none',                  // 無邊框
          padding: '12px 24px',            // 更寬的內間距
          fontSize: '16px',                // 字體大小
          borderRadius: '24px',            // 輕微圓角
          fontWeight: '500',               // 中等字重
          
          transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // 過渡效果
          cursor: 'pointer',               // 指針手勢
        }}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = '#333';   // 懸停變淺色
          e.target.style.boxShadow = '0 6px 18px rgba(0, 0, 0, 0.35)'; // 增強陰影
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = '#000';
          e.target.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.2)'; // 恢復原陰影
        }}
      >
        官網
      </Button>

    </div>
  
  );
}

export default Home;
