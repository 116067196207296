import { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import LinebotConfig from "../config/line_login";
import { v4 as uuidv4 } from 'uuid';

function useLineAuth(botType = "kah", site) {
    console.log(botType);

    const [user, setUser] = useState({ id: null, name: null, permission: null });
    const [loading, setLoading] = useState(true);

    // 獲取對應的 LINE Bot 配置
    const getLinebotConfig = (botType) => {
        const config = LinebotConfig[botType];
        if (!config) {
            throw new Error(`Invalid botType: ${botType}`);
        }
        return config;
    };

    const { CHANNEL_ID: clientId, DOMAIN: domain } = getLinebotConfig(botType);

    const redirectToLineAuthorize = () => {
        const state = uuidv4();
        const nonce = uuidv4();
        Cookies.set("oauth_state", state, { secure: true, path: "/" });
        Cookies.set("oauth_nonce", nonce, { secure: true, path: "/" });

        const scope = "openid%20profile";
        const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${domain}/${site}&state=${state}&scope=${scope}&nonce=${nonce}&max_age=3600&ui_locales=zh-TW&bot_prompt=normal`;
        window.location.href = authUrl;
    };

    const fetchUserData = async (code, state, botType, site) => {
        try {
            const savedState = Cookies.get("oauth_state");
            if (savedState !== state) throw new Error("State mismatch");

            const profileResponse = await fetch(`https://kah.dianalab.net/api/userProfile`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code, state, botType, site }),
            });

            const profile = await profileResponse.json();
            if (!profileResponse.ok) throw new Error(profile.message);

            const permissionResponse = await fetch(`https://kah.dianalab.net/api/userPermission`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: profile.user_id }),
            });

            const permission = await permissionResponse.json();
            if (!permissionResponse.ok) throw new Error(permission.message);

            Cookies.set("user_id", profile.user_id, { secure: true, path: "/" });
            Cookies.set("user_name", profile.user_name, { secure: true, path: "/" });
            Cookies.set("user_permission", JSON.stringify(permission), { secure: true, path: "/" });
            Cookies.remove("oauth_state", { path: "/" });
            Cookies.remove("oauth_nonce", { path: "/" });

            setUser({
                id: profile.user_id,
                name: profile.user_name,
                permission: permission.user_permission,
            });
            setLoading(false);
        } catch (error) {
            console.error("Error during user fetch:", error);
            redirectToLineAuthorize();
        }
    };

    const checkUserFromCookies = () => {
        const userId = Cookies.get("user_id");
        const userName = Cookies.get("user_name");
        const userPermission = JSON.parse(Cookies.get("user_permission") || "{}");

        if (userId && userName && userPermission) {
            setUser({
                id: userId,
                name: userName,
                permission: userPermission,
            });
            setLoading(false);
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            if (!checkUserFromCookies()) {
                const params = new URLSearchParams(window.location.search);
                const code = params.get("code");
                const state = params.get("state");

                if (code && state) {
                    await fetchUserData(code, state, botType, site);
                    checkUserFromCookies(); // 確保更新狀態
                } else {
                    redirectToLineAuthorize();
                }
            }
        };

        initializeAuth();
    }, []);

    // 確保在 user 更新後返回正確資料
    useEffect(() => {
        if (!loading && user.id) {
            console.log("Final user data ready for return:", user);
        }
    }, [user, loading]);

    return { user, loading };
}

export default useLineAuth;
