//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
//MaterialUI : https://mui.com/material-ui/getting-started/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeader from '../components/KahHeaderText'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'

function wish_back() {
    function handleEditRepayment(){
        window.location.href = '/manager/repayment_back/search'
    }
    return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
        <KahHeaderManager/>
        <div className="main-content" style={{ marginLeft: '250px', width: 'calc(100% - 250px)', minHeight: '100vh' }}>
            {/* <KahHeader/> */}
            <KahHeaderLogo/>
            <div className="p-3">
                    <h2>許願後台</h2>
                    <p>共生製作-許願後台</p>
                    
                    <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">編號</th>
                        <th scope="col">留言時間</th>
                        <th scope="col">留言內容</th>
                        <th scope="col">顯示</th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr>
                            <th scope="row" >1</th>
                            
                            <td>2024-03-21</td>
                            <td>餅乾</td>
                            <td><Form.Check aria-label="option 1 " defaultChecked={true}/></td>
                            </tr>
                        <tr>
                        <th scope="row" >2</th>
                            <td>2024-03-21</td>
                            <td>餅乾</td>
                            <td><Form.Check aria-label="option 1 " defaultChecked={true}/></td>
                        </tr>
                    </tbody>
                    </table>
                </div>  
                <div className="text-center d-flex flex-column ">
                <Button className="ms-3 mt-1" style={{ width: '200px' }} variant="outline-primary" size="sm">
                    <a href="https://docs.google.com/forms/d/1G7UeQKoIcagQVfM5XCshRpOzvoyZG3v_OPHE8UkRXQE/edit" style={{ textDecoration: 'none', color: 'inherit' }}>許願表單-Google</a>
                </Button>
                <Button className="ms-3 mt-3" style={{ width: '200px' }} variant="outline-success" size="sm">
                    <a href="https://docs.google.com/spreadsheets/d/1nJB5TwrhRhI8zv16OKFzx6e10v2r7IIcwQ31yk6Onzk/edit#gid=1216954832" style={{ textDecoration: 'none', color: 'inherit' }}>許願紀錄-Excel</a>
                </Button>
                </div>
            <KahFooter/>
        </div>
    </div>
  )
}

export default wish_back
