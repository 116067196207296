import { useState, useEffect } from 'react';

function useResponsiveSidebar(threshold = 768) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
  const [isMobile, setIsMobile] = useState(false); // 手機與電腦

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= threshold) {
        setIsMobile(true);
        setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
      } else {
        setIsMobile(false);
        setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
      }
    };

    // 初始化 & 監聽視窗大小改變
    handleResize();
    window.addEventListener('resize', handleResize);

    // 清除事件監聽器
    return () => window.removeEventListener('resize', handleResize);
  }, [threshold]);

  return { isSidebarOpen, isMobile };
}

export default useResponsiveSidebar;
