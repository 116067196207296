//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useLocation } from 'react-router-dom';  // 引入 useLocation 來讀取查詢參數
import { FaTrash } from 'react-icons/fa';

//MaterialUI : https://mui.com/material-ui/getting-started/ //https://mui.com/material-ui/getting-started/templates/
//https://mui.com/x/react-data-grid/editing/#full-featured-crud
import SaveIcon from '@mui/icons-material/Save';

//KAH components
import KahHeaderManager from '../components/KahHeaderManager';
import KahHeaderLogo from '../components/KahHeaderLogo';
import KahFooter from '../components/KahFooter';
import MonthSwitch from '../components/MonthSwitch';  // 引入 MonthSwitch
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';

function ClockBackSearch() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
    const location = useLocation();  // 使用 useLocation 來取得查詢字符串
    const [clockRecords, setClockRecords] = useState([]);
    const [currentMonth, setCurrentMonth] = useState('');  // 狀態保存選擇的月份
    const [employee, setEmployee] = useState('');  // 新增一個狀態來保存 employee
    const [editingIndex, setEditingIndex] = useState(null);
    const [locations, setLocations] = useState([]);
    
    // 解析查詢參數
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const employeeParam = params.get('employee');  // 獲取 'employee' 查詢參數
        if (employeeParam) {
            setEmployee(decodeURIComponent(employeeParam));  // 解碼並設置 employee 狀態
        }
    }, [location.search]);

    // 抓取打卡地點資料
    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const url = `https://kah.dianalab.net/api/manager/clock_location`;
                const response = await fetch(url);
                const data = await response.json();
                setLocations(data);
            } catch (error) {
                console.error('Failed to fetch locations:', error);
            }
        };

        fetchLocations();
    }, []);

    // 抓取打卡紀錄
    useEffect(() => {
    console.log('useEffect triggered with employee:', employee, 'and currentMonth:', currentMonth);
    // 當 employee 和 currentMonth 發生變化時發送請求
    if (employee && currentMonth) {
        const payload = {
            employee_name: employee,
            month: currentMonth
        };
        console.log('Sending payload:', payload);

        axios.post('https://kah.dianalab.net/api/get-clock-records-by-employee', payload)
            .then(response => {
                setClockRecords(response.data);
                console.log('Clock records:', response.data);
            })
            .catch(error => {
                console.error('Error fetching clock records:', error);
            });
        }
    }, [employee, currentMonth]);  // 依賴 employee 和 currentMonth

    const handleMonthChange = (month) => {
        // 僅當月份更改時，發送請求
        if (month !== currentMonth) {
          setCurrentMonth(month);
          console.log('Month updated to:', month);
        }
      };

    function Back_to_Clock() {
        window.location.href = '/manager/clock_back';
    }

    
    function handleAddClock() {
        const url = `/manager/clock_back/add?employee=${encodeURIComponent(employee)}`;
        window.location.href = url; // 導向到包含員工名稱的 URL
    }

    function handleEdit(index) {
        setEditingIndex(index); // 設置當前編輯的行
    }

    function handleSave(index) {
        // 取出紀錄的ID
        const recordId = clockRecords[index]?.id;
    
        if (!recordId) {
            console.error('Error: recordId is undefined or null');
            return;  // 若沒有 id，停止操作
        }
    
        // 構建請求 URL，將 recordId 包含在 URL 中
        const url = `https://kah.dianalab.net/api/update-clock-record/${recordId}`;
    
        // 構建更新後的紀錄資料
        const updatedRecord = {
            date: clockRecords[index].work_date,            // 修改字段名為 'date'
            on_time: clockRecords[index].on_time ,
            off_time: clockRecords[index].off_time,
            overtime: clockRecords[index].overtime,
            location: clockRecords[index].location     // 使用 location_id 而非 location
        };
        console.log('updatedRecord:', updatedRecord);
    
        // 發送 PUT 請求到後端
        axios.put(url, updatedRecord)
            .then(response => {
                console.log('Clock record updated successfully.');
                setEditingIndex(null);  // 完成編輯後重置編輯狀態
            })
            .catch(error => {
                console.error('Error updating clock record:', error);
            });
    }

    function handleDelete(recordId) {
        // 發送刪除請求到後端，將紀錄標記為刪除
        axios.post(`https://kah.dianalab.net/api/delete-clock-record/${recordId}`)
            .then(response => {
                console.log('Clock record marked as deleted.');
                // 從前端列表中移除被刪除的紀錄
                setClockRecords(clockRecords.filter(record => record.id !== recordId));
            })
            .catch(error => {
                console.error('Error deleting clock record:', error);
            });
    }
    

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        {<KahHeaderManager/>}

        {/* MAIN CONTENT */}
        <div style={{ flex: 1 }}>
        {/* logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

        {/* content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
            <h2>打卡後台</h2>
            <p>共生製作-打卡查詢後台</p>
            <MonthSwitch onMonthChange={handleMonthChange} />  {/* 傳入 handleMonthChange */}
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">員工姓名</th>
                        <th scope="col">工作日期</th>
                        <th scope="col">上班時間</th>
                        <th scope="col">下班時間</th>
                        <th scope="col">加班時間</th>
                        <th scope="col">打卡地點</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {clockRecords.length > 0 ? (
                        clockRecords.map((record, index) => (
                            <tr key={index}>
                                <th scope="row">{record.employee_name}</th>
                                {editingIndex === index ? (
                                    <>
                                        <td>
                                            <Form.Control 
                                                type="date" 
                                                size="sm" 
                                                value={record.work_date} 
                                                onChange={(e) => {
                                                    const updatedRecords = [...clockRecords];
                                                    updatedRecords[index].work_date = e.target.value;
                                                    setClockRecords(updatedRecords);
                                                }} 
                                            />
                                        </td>
                                        <td>
                                            <Form.Control 
                                                type="time"  // 設為時間選擇器
                                                size="sm" 
                                                value={record.on_time} 
                                                onChange={(e) => {
                                                    const updatedRecords = [...clockRecords];
                                                    updatedRecords[index].on_time = e.target.value;
                                                    setClockRecords(updatedRecords);
                                                }} 
                                            />
                                        </td>
                                        <td>
                                            <Form.Control 
                                                type="time"  // 設為時間選擇器
                                                size="sm" 
                                                value={record.off_time} 
                                                onChange={(e) => {
                                                    const updatedRecords = [...clockRecords];
                                                    updatedRecords[index].off_time = e.target.value;
                                                    setClockRecords(updatedRecords);
                                                }} 
                                            />
                                        </td>
                                        <td>
                                            <Form.Control 
                                                type="text" 
                                                size="sm" 
                                                value={record.overtime} 
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;

                                                    // 使用正則表達式檢查是否為有效的數字格式（允許小數點）
                                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                                        const updatedRecords = [...clockRecords];
                                                        updatedRecords[index].overtime = inputValue;
                                                        setClockRecords(updatedRecords);
                                                    }
                                                }} 
                                            />
                                        </td>
                                        <td>
                                            <Form.Select
                                                size="sm"
                                                value={record.location}
                                                onChange={(e) => {
                                                    const updatedRecords = [...clockRecords];
                                                    updatedRecords[index].location = e.target.value;
                                                    setClockRecords(updatedRecords);
                                                }}
                                            >
                                                {locations.map((loc) => (
                                                    <option key={loc.id} value={loc.location}>
                                                        {loc.location}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </td>
                                        <td>
                                        <Button variant="primary" size="sm" onClick={() => handleSave(index)}>
                                            <SaveIcon /> {/* 插入 SaveIcon */}
                                        </Button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{record.work_date}</td>
                                        <td>{record.on_time}</td>
                                        <td>{record.off_time}</td>
                                        <td>{record.overtime}</td>
                                        <td>{record.location}</td>
                                        <td>
                                            <Button variant="outline-dark" size="sm" onClick={() => handleEdit(index)}>
                                                編輯
                                            </Button>{' '}
                                            <FaTrash 
                                                style={{ color: 'gray', marginLeft: '20px', cursor: 'pointer' }}
                                                onClick={() => handleDelete(record.id)}
                                            />
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center">沒有可顯示的資料</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="text-center">
            <Button className="ms-4 mt-3" variant="success" size="sm" onClick={handleAddClock}>新增</Button>
                <Button className="ms-4 mt-3" variant="danger" size="sm" onClick={Back_to_Clock}>返回</Button>
            </div>
        </div>
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        </div>
    );
}

export default ClockBackSearch;
