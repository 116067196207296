//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
//MaterialUI : https://mui.com/material-ui/getting-started/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'
import MonthSwitch from '../components/MonthSwitch'
function Absence_back_edit() {
  const [currentMonth, setCurrentMonth] = useState('');  // 狀態保存選擇的月份
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 側邊欄狀態
  const [isMobile, setIsMobile] = useState(false);  // 手機與電腦

  // 根據視窗大小監控是否為手機視圖
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {  // 設置寬度閾值（768px）
        setIsMobile(true);
        setIsSidebarOpen(false); // 手機版預設側邊欄隱藏
      } else {
        setIsMobile(false);
        setIsSidebarOpen(true);  // 桌面版自動恢復側邊欄
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMonthChange = (month) => {
    // 僅當月份更改時，發送請求
    if (month !== currentMonth) {
      setCurrentMonth(month);
      console.log('Month updated to:', month);
    }
  };

  function Back_to_Absence(){
    window.location.href = '/manager/absence_back'
}
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    {/* SIDE BAR */}
    {<KahHeaderManager/>}

    {/* MAIN CONTENT */}
    <div style={{ flex: 1 }}>
    {/* logo */}
    <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />

    {/* content */}
    <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
      <h2>請假後台</h2>
      <p>共生製作-員工假別編輯後台</p>
      <div className='d-flex align-items-center'>
      <MonthSwitch onMonthChange={handleMonthChange} />  {/* 傳入 handleMonthChange */}
        <h5 className='mt-2'>請假紀錄</h5>
      </div>
      <table class="table" >
        <thead >
          <tr>
            <th scope="col">員工姓名</th>
            <th scope="col">到職日期</th>
            <th scope="col">特休假</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr>
            <th scope="row">員工1</th>
            <th>2021-08-09</th>
            <td>113-01-01起特休日數：8日(112年結餘)<br/>113-08-09起特休日數：10日</td>
          </tr>

        </tbody>
      </table>
      <table class="table" >
        <thead>
          <tr>
            <th scope="col">假別</th>
            <th scope="col">共計(天/時)</th>
            <th scope="col">剩餘假</th>
            <th scope="col">備註</th>
            
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr>
            <th scope="row">01-特休假</th>
            <th>2天0時</th>
            <td>
              <InputGroup>
                  <Form.Control
                  placeholder="8"
                  aria-label="absence"
                  aria-describedby="basic-addon1"
                  />
              </InputGroup>
            </td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">02-加班轉補休</th>
            <th>2天0時</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">03-病假</th>
            <th>1天0時</th>
            <td></td>
            <td>一年不超過30天</td>
          </tr>
          <tr>
            <th scope="row">04-生理假</th>
            <th>0天0時</th>
            <td></td>
            <td>三天，不併入病假/年</td>
          </tr>
          <tr>
            <th scope="row">05-公假</th>
            <th>0天0時</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">06-事假</th>
            <th>0天0時</th>
            <td></td>
            <td>一年不超過14天</td>
          </tr>
          <tr>
            <th scope="row">07-婚假</th>
            <th>0天0時</th>
            <td></td>
            <td>8天</td>
          </tr>
          <tr>
            <th scope="row">08-喪假</th>
            <th>0天0時</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">09-產假</th>
            <th>0天0時</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">10-產檢假</th>
            <th>0天0時</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">11-陪產假</th>
            <th>0天0時</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">19-其他假</th>
            <th>0天0時</th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">20-陽光假(天)</th>
            <th>0天0時</th>
            <td>
              <InputGroup>
                  <Form.Control
                  placeholder="5"
                  aria-label="absence"
                  aria-describedby="basic-addon1"
                  />
              </InputGroup>
            </td>
            <td>年度人事填入</td>
          </tr>
          <tr>
            <th scope="row">21-共生公出(時)</th>
            <th>0天0時</th>
            <td></td>
            <td></td>
          </tr>

        </tbody>
      </table>
      <div className='text-center'>
        <Button className="me-4" variant="danger" size="sm" onClick={Back_to_Absence}>取消編輯</Button>
        <Button className="ms-4" variant="success" size="sm" onClick={Back_to_Absence}>完成編輯</Button>
      </div>
      </div>
      </div>
      {/* Footer */}
      <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
    </div>
  )
}

export default Absence_back_edit
