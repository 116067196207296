//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';


//MaterialUI : https://mui.com/material-ui/getting-started/ //https://mui.com/material-ui/getting-started/templates/
//https://mui.com/x/react-data-grid/editing/#full-featured-crud
import SaveIcon from '@mui/icons-material/Save';

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeaderLogo from '../components/KahHeaderLogo'
import KahFooter from '../components/KahFooter'
//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";

function EmployeeBack() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
    const userData = useUserData(); // Cookies 取得userId、userName、userPermission資料
    const [employee, setEmployee] = useState([]);
    const [permissions, setPermissions] = useState([]);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null); // 用來追踪正在編輯的員工
    
    const allowedRoles = {
        features: [],
        line_permission:["Boss", "SYSAdmin"]
    };
    const hasPermission = useCheckPermission(userData, allowedRoles, "/manager/");
    // 抓取"後端"資料
    useEffect(() => {
        if (hasPermission != null && hasPermission) {
            setLoading(true);
            axios.post('https://kah.dianalab.net/api/get-manager-permission')
                .then(response => {
                    
                    setPermissions(response.data);
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setError(error);
                });
            axios.post('https://kah.dianalab.net/api/get-employee')
                .then(response => {
                    console.log(response.data);
                    setEmployee(response.data);
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setError(error);
                });
        }
    }, [hasPermission]);

    function handleAddEmployee() {
        // console.log("add permission");
        window.location.href = '/manager/employee/add'; // 直接導向到指定URL
    }
    const handleCancelEdit = () => {
        setEditingIndex(null);
    };
    function handleEdit(index) {
        setEditingIndex(index); // 設置當前編輯的行
    }
    function handleSave(index) {
        // 取出員工的ID
        const employeeId = employee[index]?.id;

        if (!employeeId) {
            console.error('Error: employeeId is undefined or null');
            return;  // 如果没有 id，停止进一步操作
        }
    
        // 構建請求 URL，將 employeeId 包含在 URL 中
        const url = `https://kah.dianalab.net/api/update-employee/${employeeId}`;
        
        // 更新資料並保存到後端
        // 確保所有字段都是整數
        const updatedEmployee = {
            ...employee[index],
            basic_salary: Number(employee[index].basic_salary),
            job_allowance: Number(employee[index].job_allowance),
            meal_allowance: Number(employee[index].meal_allowance),
            performance_bonus: Number(employee[index].performance_bonus),
            labor_insurance_fee: Number(employee[index].labor_insurance_fee),
            health_insurance_fee: Number(employee[index].health_insurance_fee),
        };

        console.log('updatedEmployee:', updatedEmployee);
        axios.put(url, updatedEmployee)
            .then(response => {
                console.log('Employee updated successfully.');
                setEditingIndex(null); // 完成編輯後重置編輯狀態
            })
            .catch(error => {
                console.error('Error updating employee:', error);
            });
    }
    function handleDelete(employeeId) {
        // 發送刪除請求到後端，將 Member.is_delete 設為 1
        axios.post(`https://kah.dianalab.net/api/delete-employee/${employeeId}`)
            .then(response => {
                console.log('Employee marked as deleted.');
                setEditingIndex(null); // 完成編輯後重置編輯狀態
                setEmployee(employee.filter(emp => emp.id !== employeeId)); // 從前端過濾掉刪除的員工
            })
            .catch(error => {
                console.error('Error deleting employee:', error);
            });
    }

    

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        <KahHeaderManager />
        {/* MAIN CONTENT */}
        <div style={{ flex: 1, paddingBottom: '100px' }}> {/* flex: 1 保證內容撐滿剩餘空間 */}
        {/* Logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        {/* Content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
            <h2>員工管理</h2>
            <p>共生製作-員工管理後台</p>
            <table class="table" style={{ verticalAlign: 'middle', textAlign: 'center'}}>
                <thead>
                <tr>
                    <th scope="col" style={{ width: '10%' }}>員工姓名</th>
                    <th scope="col" style={{ width: '10%' }}>本薪</th>
                    <th scope="col" style={{ width: '10%' }}>職務加給</th>
                    <th scope="col" style={{ width: '10%' }}>伙食津貼</th>
                    <th scope="col" style={{ width: '10%' }}>績效獎金</th>
                    <th scope="col" style={{ width: '10%' }}>勞保費</th>
                    <th scope="col" style={{ width: '10%' }}>健保費</th>
                    <th scope="col" style={{ width: '10%' }}>入職日期</th>
                    <th scope="col" style={{ width: '10%' }}>員工編輯</th>
                </tr>
                </thead>
                <tbody className="table-group-divider">
                    {employee.map((emp, index) => (
                        <tr key={index}>
                            <th>{emp.name}</th>
                            {editingIndex === index ? (
                            <>
                            <td><Form.Control type="text" size="sm" value={emp.basic_salary} onChange={(e) => {
                                const updatedEmployees = [...employee];
                                updatedEmployees[index].basic_salary = e.target.value;
                                setEmployee(updatedEmployees);
                            }} /></td>
                            <td><Form.Control type="text" size="sm" value={emp.job_allowance} onChange={(e) => {
                                const updatedEmployees = [...employee];
                                updatedEmployees[index].job_allowance = e.target.value;
                                setEmployee(updatedEmployees);
                            }} /></td>
                            <td><Form.Control type="text" size="sm" value={emp.meal_allowance} onChange={(e) => {
                                const updatedEmployees = [...employee];
                                updatedEmployees[index].meal_allowance = e.target.value;
                                setEmployee(updatedEmployees);
                            }} /></td>
                            <td><Form.Control type="text" size="sm" value={emp.performance_bonus} onChange={(e) => {
                                const updatedEmployees = [...employee];
                                updatedEmployees[index].performance_bonus = e.target.value;
                                setEmployee(updatedEmployees);
                            }} /></td>
                            <td><Form.Control type="text" size="sm" value={emp.labor_insurance_fee} onChange={(e) => {
                                const updatedEmployees = [...employee];
                                updatedEmployees[index].labor_insurance_fee = e.target.value;
                                setEmployee(updatedEmployees);
                            }} /></td>
                            <td><Form.Control type="text" size="sm" value={emp.health_insurance_fee} onChange={(e) => {
                                const updatedEmployees = [...employee];
                                updatedEmployees[index].health_insurance_fee = e.target.value;
                                setEmployee(updatedEmployees);
                            }} /></td>
                            <td><Form.Control type="date" size="sm" value={emp.work_date} onChange={(e) => {
                                const updatedEmployees = [...employee];
                                updatedEmployees[index].work_date = e.target.value;
                                setEmployee(updatedEmployees);
                            }} /></td>
                            <td style={{ verticalAlign: 'middle'}}>
                            <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                                <Button variant="primary" size="sm" onClick={() => handleSave(index)}>
                                    <SaveIcon /> {/* 插入 SaveIcon */}
                                </Button>
                                <FaTrash 
                                    style={{ color: 'gray', marginLeft: '20px', cursor: 'pointer' }}
                                    onClick={() => handleDelete(emp.id)}
                                />
                            </div>
                            </td>
                            </>
                            ) : (
                            <>
                            <td>{isNaN(emp.basic_salary) ? emp.basic_salary : parseInt(emp.basic_salary).toLocaleString()}</td>
                            <td>{isNaN(emp.job_allowance) ? emp.job_allowance : parseInt(emp.job_allowance).toLocaleString()}</td>
                            <td>{isNaN(emp.meal_allowance) ? emp.meal_allowance : parseInt(emp.meal_allowance).toLocaleString()}</td>
                            <td>{isNaN(emp.performance_bonus) ? emp.performance_bonus : parseInt(emp.performance_bonus).toLocaleString()}</td>
                            <td>{isNaN(emp.labor_insurance_fee) ? emp.labor_insurance_fee : parseInt(emp.labor_insurance_fee).toLocaleString()}</td>
                            <td>{isNaN(emp.health_insurance_fee) ? emp.health_insurance_fee : parseInt(emp.health_insurance_fee).toLocaleString()}</td>

                            <td>{emp.work_date}</td>
                            <td>
                                <Button variant="outline-dark" size="sm" onClick={() => handleEdit(index)}>編輯</Button>{' '}
                            </td>
                            </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="text-center">
                {editingIndex === null ? (
                    <Button className="me-4 mt-3" variant="success" size="sm" onClick={handleAddEmployee}>
                        新增
                    </Button>
                ) : (
                    <Button className="me-4 mt-3" variant="danger" size="sm" onClick={handleCancelEdit}>
                        取消
                    </Button>
                )}
            </div>
        </div>
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />    
        </div>
  )
}

export default EmployeeBack
