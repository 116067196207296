//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

//MaterialUI : https://mui.com/material-ui/getting-started/
//https://mui.com/x/react-data-grid/editing/#full-featured-crud
//https://mui.com/material-ui/getting-started/templates/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'

//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";
import useAirDatepicker from '../hooks/useAirDatepicker';

function Employee_back_add() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
    const userData = useUserData(); // Cookies 取得userId、userName資料
    const datepickerRef = useAirDatepicker({
        onSelect({ date, formattedDate }) {
            handleInputChange({ target: { id: 'work_date', value: formattedDate } });
        },
    });
    const [agents, setAgents] = useState([]); // State to store agents
    const [formData, setFormData] = useState({
        line_name: '',            // 員工Line姓名 (Agent.display_name)
        name: '',                 // 員工姓名
        work_date: '',            // 上班日期
        basic_salary: 0,        // 本薪，初始化為 0
        job_allowance: 0,       // 職務加給，初始化為 0
        meal_allowance: 0,      // 伙食津貼，初始化為 0
        performance_bonus: 0,   // 績效獎金，初始化為 0
        labor_insurance_fee: 0, // 勞保費，初始化為 0
        health_insurance_fee: 0,// 健保費，初始化為 0
        is_delete:0,
      });
    const allowedRoles = {
        features: [],
        line_permission:["Boss", "SYSAdmin"]
    };
    useCheckPermission(userData , allowedRoles, "/manager/");
    

    // 抓取"後端"資料
    useEffect(() => {
        axios.post('https://kah.dianalab.net/api/get-member-staff-name')
            .then(response => {
                setAgents(response.data); // Store agents data
            })
            .catch(error => {
                console.error('Error fetching agents:', error);
            });
    }, []);

    const formatDate = (date) => {
        const [year, month, day] = date.split('/');
        return `${year}-${month}-${day}`;
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const formattedData = {
            ...formData,
            work_date: formatDate(formData.work_date),
        };
        console.log("Form submitted with data:", formattedData);
        axios.post('https://kah.dianalab.net/api/create-employee', formattedData)
        .then(response => {
            console.log('Response:', response.data);
            window.location.href = '/manager/employee'; // 直接導向到指定URL
        })
        .catch(error => {
            console.error('There was an error creating the member permission!', error);
        });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        
        // 檢查數字
        const numericFields = [
          'basic_salary', 'job_allowance', 'meal_allowance', 
          'performance_bonus', 'labor_insurance_fee', 'health_insurance_fee', 'is_delete'
        ];
      
        setFormData({
          ...formData,
          [id]: numericFields.includes(id) ? parseInt(value, 10) || 0 : value
        });
      };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        <KahHeaderManager />

        {/* MAIN CONTENT */}
        <div style={{ flex: 1, paddingBottom: '100px' }}> {/* flex: 1 保證內容撐滿剩餘空間 */}
        {/* Logo */}
        <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        {/* Content */}
        <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
            <h2>員工管理</h2>
            <p>共生製作-員工管理後台</p>
            <Form onSubmit={handleSubmit}>
            {/* Line名稱 */}
            <Form.Group controlId="line_name">
                <Form.Label>Line名稱</Form.Label>
                <Form.Select value={formData.line_name} onChange={handleInputChange}>
                <option value="" disabled>選擇名稱</option>
                {agents.map((agent, index) => (
                    <option key={index} value={agent.display_name}>
                    {agent.display_name}
                    </option>
                ))}
                </Form.Select>
            </Form.Group>

            {/* 員工姓名 */}
            <Form.Group controlId="name" className='mt-2'>
                <Form.Label>員工姓名</Form.Label>
                <Form.Control
                type="text"
                placeholder="請輸入姓名"
                value={formData.name}
                onChange={handleInputChange}
                />
            </Form.Group> 
            
            {/* 入職日期 */}
            <Form.Group controlId="work_date" className="mt-2">
                <Form.Label>入職日期</Form.Label>
                <input
                    type="text"  // 自訂日期選擇器需要 type="text"
                    ref={datepickerRef} // 正確綁定到日期選擇器 Ref
                    className="form-control"
                    placeholder="請選擇入職日期"
                    value={formData.work_date || ''} // 確保 value 與狀態同步
                    onChange={handleInputChange} // 處理手動輸入
                />
            </Form.Group>

            {/* 本薪 */}
            <Form.Group controlId="basic_salary" className='mt-2'>
                <Form.Label>本薪</Form.Label>
                <Form.Control
                type="number"
                placeholder="請輸入本薪"
                value={formData.basic_salary}
                onChange={handleInputChange}
                />
            </Form.Group>

            {/* 職務加給 */}
            <Form.Group controlId="job_allowance" className='mt-2'>
                <Form.Label>職務加給</Form.Label>
                <Form.Control
                type="number"
                placeholder="請輸入職務加給"
                value={formData.job_allowance}
                onChange={handleInputChange}
                />
            </Form.Group>

            {/* 伙食津貼 */}
            <Form.Group controlId="meal_allowance" className='mt-2'>
                <Form.Label>伙食津貼</Form.Label>
                <Form.Control
                type="number"
                placeholder="請輸入伙食津貼"
                value={formData.meal_allowance}
                onChange={handleInputChange}
                />
            </Form.Group>

            {/* 績效獎金 */}
            <Form.Group controlId="performance_bonus" className='mt-2'>
                <Form.Label>績效獎金</Form.Label>
                <Form.Control
                type="number"
                placeholder="請輸入績效獎金"
                value={formData.performance_bonus}
                onChange={handleInputChange}
                />
            </Form.Group>

            {/* 勞保費 */}
            <Form.Group controlId="labor_insurance_fee" className='mt-2'>
                <Form.Label>勞保費</Form.Label>
                <Form.Control
                type="number"
                placeholder="請輸入勞保費"
                value={formData.labor_insurance_fee}
                onChange={handleInputChange}
                />
            </Form.Group>

            {/* 健保費 */}
            <Form.Group controlId="health_insurance_fee" className='mt-2'>
                <Form.Label>健保費</Form.Label>
                <Form.Control
                type="number"
                placeholder="請輸入健保費"
                value={formData.health_insurance_fee}
                onChange={handleInputChange}
                />
            </Form.Group>

            {/* 提交按鈕 */}
            <div className="d-flex justify-content-center">
                <Button variant="success" className="mt-3" type="submit">提交</Button>
            </div>
            </Form>
        </div>
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
      </div>
    );
}

export default Employee_back_add
