import React, { useState, useEffect } from "react";

//components
import RepayKahHeader from './components/RepayKahHeader'
import MUITable from './components/MUITable'

const invoiceBoxStyle = {
    maxWidth: '800px',
    margin: 'auto',
    padding: '23px',
  };
  
  const contentBoxStyle = {
    maxWidth: '800px',
    margin: 'auto',
    padding: '19px',
  };
  
  const titleStyle = {
    backgroundColor: 'white',
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '15px',
  };
  
  const hrStyle = {
    height: '4px',
    backgroundColor: 'black',
    marginTop:'10px',
    marginBottom:'5px'
  };
  
  const sidebysideStyle = {
    display: 'flex',
  };
  
  const idStyle = {
    fontSize: '20px',
    color: 'grey',
    
  };
  
  const departmentStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '22px',
    marginRight: '20px',
    marginBottom: '10px',
  };
  
  const signStyle = {
    fontSize: '18px',
    marginTop: '15px',
    marginBottom: '5px',
  };
  
  const totalStyle = {
    textAlign: "right",
    marginTop: '15px',
    fontSize: '22px',
    fontWeight: "bold"
  };
  
  const invisibleStyle = {
    flex: 1,
    visibility: 'hidden', // 使得第三個元素不可見
  };

function Repayment(){
  const [data, setData] = useState([]); // 新增狀態來儲存從 API 獲取的數據

  useEffect(() => {
    // 示例的 API 請求
    const fetchData = async () => {
      try {
        const response = await fetch('https://kahfast-20240123-e909e0613445.herokuapp.com/repaymentGetData'); //http://127.0.0.1:8000/repaymentGetData
        const jsonData = await response.json();
        setData(jsonData.data); // 假設後端回傳的格式是 { data: [...] }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // 依賴數組為空，表示這個效果只在組件掛載時運行一次

  function amount(data) {
    let total = 0;
    data.forEach(row => {
      const num = parseFloat(row.合計);
      if (!isNaN(num)) {
        total += num;
      }
    });
  
    // 使用 Intl.NumberFormat 來格式化數字為台幣貨幣形式
    const formatter = new Intl.NumberFormat('zh-TW', {
      style: 'currency',
      currency: 'TWD',
      // 如果不想要小數部分，可以設定這些選項
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    return formatter.format(Math.round(total)); // 返回格式化後的字符串，如 "NT$27,381"
  }


    return(
        
    <div>
        
        <div style={invoiceBoxStyle}>
      
        <RepayKahHeader/>

        <hr style={hrStyle}></hr>

        <div style={contentBoxStyle}>
            <div style={{...sidebysideStyle,justifyContent: 'space-between'}}>
            <div >
                <div style={sidebysideStyle}>
                <div style={titleStyle}>2024 01 月支出代墊款</div>
                </div>

                <div style={idStyle}>
                <div style={{...sidebysideStyle,marginTop: '-5px'}}>
                    <p>姓名</p>
                    <p style={{marginLeft:'19px'}}>{data[0]?.請款人}</p>
                </div>
                <div style={{...sidebysideStyle,marginTop: '-20px',marginBottom: '-15px'}}>
                    <p>部門</p>  
                    <p style={{marginLeft:'19px'}}>{data[0]?.部門}</p>
                </div>
                </div>
            </div>

            <div style={departmentStyle}>
                <div>設計</div>
                <div>行政</div>
                <div>會計</div>
            </div>
            </div>
            <hr style={{ ...hrStyle, height: '2px' ,marginTop:'0px'}}></hr>
            <MUITable data={data}/>
            <hr style={{ ...hrStyle, height: '2px' ,marginTop:'0px'}}></hr>
            
            <div style={{...sidebysideStyle,justifyContent: 'space-between'}}>
            <div style={signStyle}>&#x25CE;簽名並簽屬日期</div>
            <div style={totalStyle}>{amount(data)}</div>
            </div>

            <div style={{...sidebysideStyle,justifyContent: 'space-between'}}>
            <div style={{...totalStyle}}>申請人</div>
            <div style={invisibleStyle}></div>
            <div style={{...totalStyle}}>主管</div>
            <div style={invisibleStyle}></div>
            </div>

            </div>
        </div>
    </div>

    )
}

export default Repayment