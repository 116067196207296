//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

//MaterialUI : https://mui.com/material-ui/getting-started/
//https://mui.com/material-ui/getting-started/templates/
//https://mui.com/x/react-data-grid/editing/#full-featured-crud

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo'

//KAH hooks
import useResponsiveSidebar from '../hooks/useResponsiveSidebar';
import useUserData from "../hooks/useUserData";
import useCheckPermission from "../hooks/useCheckPermission";
import useAirDatepicker from '../hooks/useAirDatepicker';

function Schedule_back_add() {
    const { isSidebarOpen, isMobile } = useResponsiveSidebar(768);
    const userData = useUserData(); // Cookies 取得userId、userName資料
    const datepickerRef = useAirDatepicker({
        onSelect({ date, formattedDate }) {
            handleInputChange({ target: { id: 'project_start', value: formattedDate } });
        },
    });
    const [formData, setFormData] = useState({
        name: null,
        project_start: null,
        project_end: null,
        project_url: null,
      });
    const [schedules, setSchedules] = useState([]); // 後端資料

    const allowedRoles = {
        features: [],
        line_permission:["Boss", "SYSAdmin", "HR"]
    };
    useCheckPermission(userData , allowedRoles, "/manager/");

    // 抓取"後端"資料
    // useEffect(() => {
    //     axios.post('https://kah.dianalab.net/api/get-add-schedule-name')
    //         .then(response => {
    //             setSchedules(response.data); // Store schedules data
    //         })
    //         .catch(error => {
    //             console.error('Error fetching schedule:', error);
    //         });
    //   }, []);

    const formatDate = (date) => {
        const [year, month, day] = date.split('/');
        return `${year}-${month}-${day}`;
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // 驗證必填欄位
        if (!formData.name) {
            alert('請輸入專案名稱');
            return;
        }
        if (!formData.project_start) {
            alert('請選擇專案開始日期');
            return;
        }
        
        const formattedData = {
            ...formData,
            project_start: formatDate(formData.project_start),
            project_end: formData.project_end ? formatDate(formData.project_end) : null,
            project_url: formData.project_url || null, // 將空字串轉換為 null
        };

        console.log('Formatted Data:', formattedData);

        axios.post('https://kah.dianalab.net/api/createSchedule', formattedData)
            .then((response) => {
                console.log('Response:', response.data);
                window.location.href = '/manager/schedule_back';
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Error Response:', error.response.data);
                } else {
                    console.error('Unexpected Error:', error);
                }
            });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
      }; 

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* SIDE BAR */}
        <KahHeaderManager />

        {/* MAIN CONTENT */}
        <div style={{ flex: 1, paddingBottom: '100px' }}> {/* flex: 1 保證內容撐滿剩餘空間 */}
            {/* Logo */}
            <KahHeaderLogo isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
            {/* Content */}
            <div className="p-3" style={{ textAlign: 'left', marginLeft: isSidebarOpen && !isMobile ? '270px' : '0' }}>
                <h2>進度後台</h2>
                <p>共生製作-進度後台</p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                    <Form.Label>專案名稱</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="請輸入專案名稱"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    </Form.Group>

                    <Form.Group controlId="project_start" className='mt-2'>
                    <Form.Label>專案開始</Form.Label>
                    <input
                                type="text"
                                ref={datepickerRef} // 將日期選擇器綁定到 ref
                                className="form-control"
                                placeholder="請選擇開始時間"
                                value={formData.project_start} // 確保雙向綁定
                                onChange={handleInputChange} // 處理手動輸入情況
                    />
                    </Form.Group>

                    {/* <Form.Group controlId="project_url" className='mt-2'>
                    <Form.Label>工程期程表</Form.Label>
                    <Form.Control
                        type="url"
                        placeholder="請輸入網址"
                        value={formData.project_url}
                        onChange={handleInputChange}
                    />
                    </Form.Group> */}
                    

                    <div className="d-flex justify-content-center">
                        <Button variant="success" className="mt-3" type="submit">提交</Button>
                    </div>

                </Form>
            </div>
        </div>
        {/* Footer */}
        <KahFooter isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
        </div>
    )
}

export default Schedule_back_add
