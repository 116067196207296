// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar'
import { Container, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function KahNavbar() {
  return (
  <Navbar bg="dark" data-bs-theme="dark">
    <Container>
      <Navbar.Brand href="/">KAH Design</Navbar.Brand>
      {/* <Nav className="me-auto">
        <Nav.Link href="./">Home</Nav.Link>
      </Nav> */}
    </Container>
  </Navbar>
  )
}

function Home_back() {
  
  return (
    <div>
      <KahNavbar/>
      <ul>
        <h3 className='mt-3 mb-2'>圖文選單-內部</h3>

        <h5 className='mt-3 mb-2'>打卡</h5>
        <li><Link to="/clock">打卡</Link></li>
        <li><Link to="/work_overtime_form">加班</Link></li>
        

        <h5 className='mt-3 mb-2'>請假</h5>
        <li><Link to="/absence">請假</Link></li>
        <li><Link to="/absence_form">請假表單</Link></li>

        <h5 className='mt-3 mb-2'>請款</h5>
        <li><Link to="/repayment_search">請款搜尋</Link></li>
        <li><Link to="/repayment">請款</Link></li>
        <li><Link to="/repayment_form">請款表單</Link></li>

        <h5 className='mt-3 mb-2'>薪資</h5>
        <li><Link to="/salary">薪資</Link></li>

        <h5 className='mt-3 mb-2'>日誌</h5>
        <li><Link to="/schedule">進度</Link></li>
        <li><Link to="/scheduleadd">進度添加</Link></li>

        {/* <li><Link to="/wish">許願</Link></li> */}
        
        <h3 className='mt-3 mb-2'>圖文選單-業主</h3>
        <li><Link to="/repair">報修</Link></li>
        <li><Link to="/salarlylaw">勞基法</Link></li>

        {/* <h3 className='mt-3 mb-2'>圖文選單-一般</h3> */}

        <h3 className='mt-3 mb-2'>後台</h3>
        <li><Link to="/manager">後台管理</Link></li>
        <li><Link to="/clock_delete">打卡刪除</Link></li>
      </ul>
      {/* 其他內容 */}
    </div>
  
  );
}

export default Home_back;
