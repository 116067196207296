import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
function useCheckPermission(userData, allowedRoles, redirectPath) {
    const navigate = useNavigate();
    const [hasPermission, setHasPermission] = useState(null);

    useEffect(() => {
        if (!userData) {
            return ; // 如果 userData 尚未加載，停止執行
        }

        const fetchUserPermission = async () => {
            try {
                const response = await axios.post('https://kah.dianalab.net/api/userPermission', { userId: userData.userId });
                const userPermission = response.data?.user_permission;
                if (!userPermission) {
                    console.warn("Failed to fetch user permissions from backend.沒有權限資料");
                    navigate("/");
                    return;
                }

                const { line_permission, features } = userPermission;

                // 檢查 line_permission 權限
                if (allowedRoles.line_permission.length > 0) {
                    if (!allowedRoles.line_permission.includes(line_permission)) {
                        console.warn("Insufficient line_permission, redirecting to", redirectPath);
                        setHasPermission(false);
                        navigate(redirectPath, { replace: true });
                        return;
                    }
                }

                // 檢查 features 權限
                if (allowedRoles.features.length > 0) {
                    const hasFeaturePermission = allowedRoles.features.every(
                        (feature) => features[feature] === 1
                    );
                    if (!hasFeaturePermission) {
                        console.warn("Insufficient feature permission.");
                        setHasPermission(false);
                        return;
                    }
                }

                // 使用者有權限
                setHasPermission(true);

            } catch (error) {
                console.error("Error fetching user permissions:", error);
                setHasPermission(false);
                navigate(redirectPath, { replace: true });
            }
        };
        fetchUserPermission();

    }, [userData, allowedRoles, redirectPath, navigate]);
    return hasPermission;
}

export default useCheckPermission;
