//RractBoostrap : https://react-bootstrap.netlify.app/docs/components/buttons/
import React,{useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; //Bootstrap - CSS 樣式文件
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
//MaterialUI : https://mui.com/material-ui/getting-started/
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; //MaterialUI icon : https://mui.com/material-ui/material-icons/

//KAH components
import KahHeaderManager from '../components/KahHeaderManager'
import KahHeader from '../components/KahHeaderText'
import KahFooter from '../components/KahFooter'
import KahHeaderLogo from '../components/KahHeaderLogo2'
import MonthSwitch from '../components/MonthSwitch'
function salary_back_edit() {
    function Back_to_salary_back(){
        window.location.href = '/manager/salary_back'
    }
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
        <KahHeaderManager/>
        <div className="main-content" style={{ marginLeft: '250px', width: 'calc(100% - 250px)', minHeight: '100vh' }}>
            <KahHeaderLogo/>
            <div className="p-3">
                    <h2>薪資後台</h2>
                    <p>共生製作-薪資編輯後台</p>
                    <p>到職日期：2024/04/03</p>
                    <div className='d-flex align-items-center'>
                        <MonthSwitch/>
                        <h5 className='mt-2'>薪資紀錄</h5>
                    </div>
                    <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">員工姓名</th>
                        <th scope="col">本薪</th>
                        <th scope="col">加班費</th>
                        <th scope="col">代墊款</th>
                        <th scope="col">獎金</th>
                        <th scope="col">請假</th>
                        <th scope="col">薪水加總</th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr>
                            <th scope="row" >員工1</th>
                            <td>
                                <InputGroup>
                                    <Form.Control
                                    placeholder="30000"
                                    aria-label="salary"
                                    aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup>
                                    <Form.Control
                                    placeholder="10000"
                                    aria-label="salary"
                                    aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                7500
                            </td>
                            <td>
                                <InputGroup>
                                    <Form.Control
                                    placeholder="2500"
                                    aria-label="salary"
                                    aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup>
                                    <Form.Control
                                    placeholder="-1200"
                                    aria-label="salary"
                                    aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </td>
                            <td>total</td> 
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div className="text-center">
                    <Button variant="outline-dark" size="sm">重新計算</Button>
                </div>
                <div className="text-center">
                        
                        <Button className="me-4 mt-4" variant="success" size="sm" onClick={Back_to_salary_back}>確認</Button>
                        <Button className="ms-4 mt-4" variant="danger" size="sm" onClick={Back_to_salary_back}>返回</Button>
                </div>
            <KahFooter/>
        </div>
    </div>
  )
}

export default salary_back_edit
